.b-fill-handle {
    width               : .8em;
    height              : .8em;
    transform           : translate(-50%, -50%);
    position            : absolute;
    background          : $grid-cell-focused-outline-color;
    cursor              : crosshair;
    border              : 1px solid $grid-background-color;
    z-index             : 200;
    background-clip     : content-box;
    user-select         : none;
    -webkit-user-select : none;
}


.b-fill-selection-border {
    position            : absolute;
    border              : $grid-cell-focused-outline-width $grid-cell-focused-outline-style $grid-cell-focused-outline-color;
    border-radius       : .1em;
    pointer-events      : none;
    z-index             : 2;
    -webkit-user-select : none;
    user-select         : none;
}

.b-indicate-crop {
    opacity : 0.4;
}

// Used when the fill handle is drawn where there is not enough width for it
.b-fill-handle-right-edge {
    border-right : 0;
    transform    : translate(-100%, -50%);
    width        : 0.5em; // Slightly larger than half the original size
}

.b-rtl .b-fill-handle {
    transform           : translate(50%, -50%);

    // For RTL
    &.b-fill-handle-left-edge {
        border-right : 0;
        transform    : translate(100%, -50%);
        width        : 0.5em; // Slightly larger than half the original size
    }
}
