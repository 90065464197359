@import '../../variables';

.b-menu {
    min-height     : 20px;
    outline        : none;
    display        : flex;
    flex-direction : column;
    align-items    : stretch;
    @include user-select-none();

    .b-panel-content {
        background-color : $menu-background-color;
    }

    &.b-empty::after {
        content : attr(data-empty-text);
        display : block;
        padding : .8em;
        color   : $menu-color;
    }

    .b-icon:before, .b-icon-fw:before {
        min-width : 1.3em; // make sure icons of different width have same width for proper text alignment
    }

    // leave empty space where there are no expand icons
    &.b-menu-with-submenu .b-menuitem:not(.b-has-submenu) .b-menu-text {
        margin-inline-end : 2em;
    }
}

.b-menu-content {
    justify-content : flex-start;
    align-items     : stretch;
    padding         : 0;

    > :not(.b-menuitem) {
        margin  : 0.2em 0.6em;
        display : flex;

        // When first or last, inset them a little more.
        &:first-child {
            margin-top : 0.4em;
        }

        &:last-child {
            margin-bottom : 0.4em;
        }
    }

    > .b-button {
        justify-content : flex-start
    }
}


.b-menuitem {
    flex-shrink : 0; // Must not try to shrink when there's vertical overflow
    padding     : .8em;
    color       : $menu-color;
    cursor      : pointer;
    transition  : background-color 0.2s, color 0.2s;
    border-top  : 1px dotted transparentize($menu-item-border-color, .2);
    font-size   : 1em;
    min-width   : 7em;
    display     : flex;
    align-items : center; // Center vertically

    &.b-disabled {
        opacity : .5;
    }

    &:first-child {
        border-top              : none;
        border-top-right-radius : $widget-border-radius;
        border-top-left-radius  : $widget-border-radius;
    }

    &:last-child {
        border-bottom-right-radius : $widget-border-radius;
        border-bottom-left-radius  : $widget-border-radius;
    }

    &.b-separator:not(.b-first-visible-child) {
        border-top : 1px solid $menu-item-separator-color;
    }

    // Disabled menu items are focusable but cannot be activated.
    // https://www.w3.org/TR/wai-aria-practices/#h-note-17
    &.b-contains-focus,
    &:focus {
        outline          : none;
        background-color : $menu-highlight-color;
        color            : $menu-active-color;
    }

    span.b-menu-text {
        flex                : 1 1 auto;
        white-space         : nowrap;
        margin-inline-start : 0.6em;
        overflow            : hidden;
        text-overflow       : ellipsis;
        min-width           : 5em;
    }

    &.b-has-submenu {
        .b-icon-sub-menu {
            margin-inline-start : .3em;
        }
    }

    &.b-rtl .b-icon-sub-menu::before {
        transform : scaleX(-1);
    }
}

a.b-menuitem:hover .b-menu-text {
    text-decoration : underline;
}


.b-menu-with-icon {
    // Fixed width on menu items, to allow text to align
    .b-menuitem-icon {
        width             : $menu-item-icon-width;
        text-align        : center;
        flex-shrink       : 0;
        margin-inline-end : 0.5em;
    }

    // Item without icon
    span.b-menu-text {
        margin-inline-start : $menu-item-icon-width + 0.5em;
    }

    // Item with icon, no margin on text since icon has width set above
    .b-menuitem-icon ~ span.b-menu-text {
        margin-inline-start : 0;
    }
}
