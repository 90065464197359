.b-chip {
    display          : flex;
    align-items      : center;
    padding-block    : $chip-padding-block;
    padding-inline   : $chip-padding-inline;
    border-radius    : $chip-border-radius;
    background-color : $chip-background-color;
    color            : $chip-color;
    transition       : background-color .3s, color .3s;

    .b-icon {
        height           : $chip-icon-size;
        width            : $chip-icon-size;
        border-radius    : 50%;
        display          : flex;
        align-items      : center;
        justify-content  : center;
        background-color : $chip-icon-background-color;
        color            : $chip-icon-color;

        // Any added icon to the left gets a margin on the right before the text
        &:first-child:not(.b-close-icon) {
            margin-inline-end   : $chip-icon-spacing;
            // Negative margin to move closer to edge
            margin-inline-start : -.4em;
        }
    }

    &:hover {
        background-color : $chip-hover-background-color;
        color            : $chip-hover-color;

        .b-icon {
            background-color : $chip-hover-icon-background-color;
            color            : $chip-hover-icon-color;
        }
    }

    &.b-selected {
        background-color : $chip-selected-background-color;
        color            : $chip-selected-color;

        .b-icon {
            background-color : $chip-selected-icon-background-color;
            color            : $chip-selected-icon-color;
        }

        &:hover {
            background-color : $chip-hover-selected-background-color;
            color            : $chip-hover-selected-color;

            .b-icon {
                background-color : $chip-hover-selected-icon-background-color;
                color            : $chip-hover-selected-icon-color;
            }
        }
    }

    &.b-active {
        outline        : 1px solid $widget-highlight-color;
        outline-offset : 2px;
    }

    .b-close-icon {
        margin-inline-start : $chip-icon-spacing;
        cursor              : pointer;
    }
}

.b-chipview {
    background-color : $chipview-background-color;
    gap              : $chip-gap;

    &.b-empty::after {
        display : none;
    }

    input {
        padding-top    : $chip-padding-block * 2;
        padding-bottom : calc(#{$chip-gap + $chip-padding-block} + 1px);
    }

    // With a close icon, it needs less right padding.
    &.b-chips-closable {
        .b-chip {
            padding-inline-end : $chip-close-icon-padding;
        }
    }
}
