@import '../../functions';
@import '../../variables';

.b-tabpanel {
    &-tabs {
        display          : flex;
        flex             : 0 0 auto;
        flex-direction   : row;

        // The bottom of the tab strip sits under the container's top border.
        // The active tab gets a z-index to rise above to "connect" to the content.
        margin-bottom    : -$tabpanel-border-width;

        background-color : $tabpanel-tabs-background-color;

        .b-toolbar & {
            // Toolbar adds gap by default, unwanted for tabs
            gap : 0;
        }
    }

    &-tab {
        display                 : flex;
        padding                 : $tabpanel-tab-padding;
        cursor                  : pointer;
        transition              : background-color 0.2s, color 0.2s;
        align-items             : center;
        justify-content         : center;
        font-size               : $tabpanel-tab-font-size;
        font-weight             : $tabpanel-tab-font-weight;
        text-transform          : $tabpanel-tab-text-transform;

        color                   : $tabpanel-tab-color;
        background-color        : $tabpanel-tab-background-color;

        border-width            : $tabpanel-tab-border-width;
        border-style            : $tabpanel-tab-border-style;
        border-color            : $tabpanel-tab-border-color;

        border-top-left-radius  : $widget-border-radius;
        border-top-right-radius : $widget-border-radius;

        &.b-rotate-vertical {
            min-width  : $tabpanel-tab-height;
            min-height : $tabpanel-tab-min-width;
        }

        &:not(.b-rotate-vertical) {
            min-height : $tabpanel-tab-height;
            min-width  : $tabpanel-tab-min-width;
        }

        &.b-active {
            background-color                : $tabpanel-tab-active-background-color;
            border-color                    : $tabpanel-tab-active-border-color;
            color                           : $tabpanel-tab-active-color;
            font-weight                     : $tabpanel-tab-active-font-weight;

            // Occludes the top border of the b-tabpanel-body element.
            z-index                         : 1;

            --b-tabpanel-tabs-pseudo-border : #{$tabpanel-tab-active-pseudo-border};
        }

        .b-dock-top > .b-tabpanel-tabs > & {
            &::before {
                content       : '';
                position      : absolute;
                bottom        : 0;
                inset-inline  : 0;
                border-bottom : var(--b-tabpanel-tabs-pseudo-border);
            }
        }

        .b-dock-left > .b-tabpanel-tabs > & {
            //          T                       R
            //      +-------+               +-------+
            //    L |       | R     -->   T |       | B
            //      +-------+               +-------+
            //          B                       L
            border-width : right($tabpanel-tab-border-width) bottom($tabpanel-tab-border-width) left($tabpanel-tab-border-width) top($tabpanel-tab-border-width);
        }

        .b-dock-right > .b-tabpanel-tabs > & {
            //          T                       L
            //      +-------+               +-------+
            //    L |       | R     -->   B |       | T
            //      +-------+               +-------+
            //          B                       R
            border-width : left($tabpanel-tab-border-width) top($tabpanel-tab-border-width) right($tabpanel-tab-border-width) bottom($tabpanel-tab-border-width);
        }

        .b-dock-bottom > .b-tabpanel-tabs > & {
            //          T                       B
            //      +-------+               +-------+
            //    L |       | R     -->   L |       | R
            //      +-------+               +-------+
            //          B                       T
            border-width : bottom($tabpanel-tab-border-width) right($tabpanel-tab-border-width) top($tabpanel-tab-border-width) left($tabpanel-tab-border-width);
        }

        &.b-hidden {
            display : none !important; // ugly but needed since subclasses can override normal display with more specificity
        }

        &.b-disabled {
            background-color : transparent;
            color            : transparentize($tabpanel-tab-color, .7);
            border-color     : transparentize($tabpanel-tab-border-color, .2);
        }

        &:focus {
            outline : none;
        }

        &:hover:not(.b-active, .b-disabled),
        &:focus:not(.b-active, .b-disabled) {
            color                           : $tabpanel-tab-hover-color;

            --b-tabpanel-tabs-pseudo-border : #{$tabpanel-tab-hover-pseudo-border};
        }

        &:hover:not(.b-active, .b-disabled) {
            background-color : $tabpanel-tab-hover-background-color;
        }
    }

    &-body.b-card-container {
        background-color : $tabpanel-background-color;
        border-radius    : $widget-border-radius;
        border           : $tabpanel-border-width solid $tabpanel-border-color;
        flex             : 1 0 auto;
        padding          : $tabpanel-body-padding;
    }

    &[data-active-index="0"] {
        .b-tabpanel-body {
            border-top-left-radius : 0;
        }
    }

    .b-html {
        color : $container-color;
    }
}

// We need this extra specificity to overcome toolbar styles:
.b-toolbar.b-tabbar {
    background-color : var(--panel-background-color);

    > .b-tabpanel-tabs {
        min-height : auto;
        padding    : 0;
    }

    &.b-dock-top {
        border-bottom : none;

        &::before {
            content       : '';
            position      : absolute;
            bottom        : 0;
            inset-inline  : 0;
            border-bottom : $tabpanel-tabs-border-bottom;
        }
    }

    &.b-dock-right {
        border-inline-start : $tabpanel-tabs-border-bottom;
    }

    &.b-dock-bottom {
        border-top : $tabpanel-tabs-border-bottom;
    }

    &.b-dock-left {
        border-inline-end : $tabpanel-tabs-border-bottom;
    }
}

.b-tabbar {
    &.b-dock-top,
    &.b-dock-bottom,
    &.b-dock-left,
    &.b-dock-right {
        .b-tabpanel-tabs > .b-widget:not(.b-last-visible-child) {
            margin-inline-end : 0;
            margin-bottom     : 0;
        }
    }
}
