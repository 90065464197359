@import '../variables';

$background-color : lighten($grid-header-background-color, 3%);

.b-verticaltimeaxiscolumn {
    flex-flow         : column nowrap;
    align-items       : stretch;
    padding           : 0;
    border-inline-end : none;
    // Using !important to override rules for hover, selected, focused cell etc. in a single line
    background-color  : $background-color !important;

    &.b-grid-cell.b-focused:after {
        display : none;
    }
}

.b-verticaltimeaxis {
    flex    : 1 0 100%;
    contain : strict;

    .b-sch-header-row {
        flex-direction : column;
        flex           : 1;
    }

    .b-sch-header-timeaxis-cell {
        width               : 100%;
        text-transform      : $grid-header-text-transform;
        padding             : 0;
        border-inline-start : 0 none;
    }

    .b-sch-header-row.b-lowest {
        .b-sch-header-timeaxis-cell {
            border-inline-end : none;
            border-bottom     : none;
            justify-content   : flex-end;
            align-items       : flex-start;
            contain           : unset; // allow overflow

            .b-sch-header-text {
                padding-inline-end : 0.5em;
                margin-top         : -0.75em;
            }

            // Hover effects only on lowest TimeAxis cells.
            &:hover {
                background-color : $grid-header-background-color;
            }
        }
    }

    // Rotate text in all but the lowest level header
    .b-sch-header-row:not(.b-lowest) {
        flex : 0 0 2em;

        .b-sch-header-timeaxis-cell {
            align-items       : flex-start;
            border-inline-end : 1px solid $grid-header-border-color;
        }

        .b-sch-header-text {
            // It's rotated by 180 degrees, so start padding is padding-bottom.
            padding-bottom   : left($event-bar-content-margin);
            writing-mode     : vertical-lr;
            -ms-writing-mode : tb-lr;
            transform        : rotate(180deg);

            // TODO RTL
        }
    }
}

.b-sch-vertical {
    // It's the scheduler content element that carries this class in vertical mode.
    // All containing elements have to be overflow: visible
    .b-sticky-headers {
        .b-content-element,
        .b-grid-subgrid-locked,
        .b-verticaltimeaxis-row,
        .b-verticaltimeaxiscolumn,
        .b-verticaltimeaxis,
        .b-sch-header-row,
        .b-sch-header-timeaxis-cell {
            overflow : visible;
        }
    }
}
