@import "../variables";

.b-parent-area {
    position       : absolute;
    z-index        : 4;
    background     : $parent-area-background;
    pointer-events : none;
}

.b-animating .b-parent-area {
    transition : left 0.2s, top 0.2s, width 0.2s;
}
