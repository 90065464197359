@import '../../variables';

.b-rowexpander-body {
    display     : flex;
    border-top  : 1px solid $grid-cell-border-color;
    left        : 0;
    right       : 0;
    color       : $grid-cell-color;
    font-weight : $grid-cell-font-weight;
    &, &.b-no-resizeobserver.b-resize-monitored {
        position : absolute;
    }
}

/* Applies when the rowExpander has spanRegions set to true */
.b-grid-vertical-scroller > .b-rowexpander-body {
    z-index : 100;
    transition : height .3s, top .3s;
    overflow : hidden;
}

.b-rowexpander-loading {
    .b-grid-row > & {
        position        : absolute;
    }
    justify-content : center;
    align-items     : center;
    font-size       : 1.2em;
    display         : flex;
    width           : 100%;

    .b-icon-spinner {
        margin-inline-end : .5em;
    }
}

.b-gridbase .b-grid-row [data-column="expanderActionColumn"] .b-icon {
    transition : transform 0.15s ease-in-out;
    font-size  : 1em;
    transform: rotate(180deg);
}

.b-rowexpander-disabled [data-column="expanderActionColumn"] {
    opacity        : 0.2;
    pointer-events : none;
}

.b-rowexpander.b-gridbase.b-animating.b-rowexpander-animating {

    &.b-autoheight {
        // When autoHeight, resize of these 2 elements clips collapsing animation when using nested grids
        .b-grid-body-container, .b-grid-vertical-scroller {
            transition : height .3s; // Animate these as well
            overflow-y : hidden !important; // Scrollbar will appear and disappear otherwise
        }

        // Scrollbar header pad till appear and disappear otherwise
        .b-yscroll-pad {
            display : none;
        }
    }

    .b-grid-row {
        transition : height .3s, transform .3s;
    }
}

.b-gridbase .b-rowexpander-row-expanded:not(.b-row-is-collapsing) [data-column="expanderActionColumn"] {
    .b-icon-collapse-left {
        transform : rotate(270deg);
    }

    .b-icon-collapse-right {
        transform : rotate(90deg);
    }
}


.b-grid-header[data-column="expanderActionColumn"] .b-grid-header-text-content:empty {
    display : none;
}
