@import '../../variables';

.b-grid-cell {
    &.b-row-number-cell,
    &.b-sequence-cell {
        background-color  : $rownumber-background;
        border-inline-end : 1px solid $rownumber-border-color;
    }
}

.b-grid-row.b-hover {
    .b-grid-cell:hover {
        &.b-sequence-cell,
        &.b-row-number-cell {
            border-inline-end : 1px solid $rownumber-border-color;
        }
    }
}

.b-group-row {
    .b-grid-cell:first-child {
        &.b-sequence-cell,
        &.b-row-number-cell {
            border-inline-end : 1px solid $rownumber-border-color;
        }
    }
}

.b-gridbase:focus {
    .b-grid-row.b-selected {
        .b-grid-cell.b-selected {
            &.b-row-number-cell,
            &.b-sequence-cell {
                border-inline-end : 1px solid $grid-cell-border-color;
            }
        }
    }
}

.b-gridbase .b-grid-row.b-selected .b-row-number-cell,
.b-gridbase .b-grid-row .b-grid-cell.b-selected.b-row-number-cell,
.b-gridbase .b-grid-row.b-selected .b-sequence-cell,
.b-gridbase .b-grid-row .b-grid-cell.b-selected.b-sequence-cell {
    background-color : $rownumber-selected-background;
}

.b-gridbase:not(.b-moving-splitter) .b-grid-subgrid:not(.b-timeaxissubgrid) .b-grid-row:not(.b-group-row) {
    &.b-hover .b-grid-cell.b-row-number-cell,
    .b-grid-cell.b-row-number-cell.b-hover,
    &.b-hover .b-grid-cell.b-sequence-cell,
    .b-grid-cell.b-sequence-cell.b-hover{
        background-color : $rownumber-hover-background;
    }
}
