@import '../variables';

.b-timeranges-header-canvas .b-sch-nonworkingtime {
    background-color : $weekend-background-color;
    // non-working time is less relevant than individual ranges
    z-index          : $range-zindex - 1;
    pointer-events   : none;
}

.b-sch-nonworkingtime {
    z-index  : $nonworkingtime-zindex;
}

