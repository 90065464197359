@import '../variables.scss';

.b-sch-highlight-container {
    position           : absolute;
    top                : 0;
    inset-inline-start : 0;
    pointer-events     : none;

    .b-unavailable {
        background : $schedulerpro-unavailable-time-background;
    }
}

.b-sch-highlighted-range {
    position        : absolute;
    display         : flex;
    align-items     : center;
    justify-content : center;
    color           : $schedulerpro-highlighted-range-color;
    font-size       : $event-font-size;
}

.b-sch-highlighted-range-name {
    text-overflow : ellipsis;
    overflow      : hidden;
}

.b-sch-highlighted-range-default {
    background         : $schedulerpro-highlighted-range-background;
    border             : 2px dashed $schedulerpro-highlighted-range-border-color;
    animation-name     : fadeInOpacity;
    animation-duration : 0.2s;
}

.b-timelinebase.b-transition-highlight {
    .b-sch-highlighted-range {
        transition-property : opacity;
        transition-duration : 0.3s;

        &[data-sync-id] {
            transition-property : width, height, inset-inline-start, top, opacity;
        }
    }
}

