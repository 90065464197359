@use "sass:math";
@import '../variables';

.b-float-root > .b-floating.b-interaction-tooltip {
    pointer-events : none;
}

.b-sch-line {
    // For perfect alignment with the time axis column lines
    margin-inline-start : -1px;
}

.b-timeranges-canvas {
    display : contents;
}

.b-sch-timerange {
    display            : flex;
    flex-direction     : row;
    align-items        : center;
    position           : absolute;
    inset-inline-start : 0;
    overflow           : hidden;
    justify-content    : center;

    color              : $range-color;
    z-index            : $range-zindex;
    cursor             : pointer;

    &.b-over-resize-handle {
        cursor : ew-resize;
    }

    // Move hovered range to front, unless dragging
    .b-timelinebase:not(.b-dragging-timerange) &:hover {
        z-index : $range-zindex + 3;
    }

    label {
        font-size     : .8em;

        color         : inherit;
        white-space   : nowrap;
        cursor        : inherit;
        text-overflow : ellipsis;
        overflow      : hidden;
        padding       : 0.3em;
    }

    i {
        margin-inline-end : .5em;
    }

    .b-timeranges-body-canvas &.b-narrow-range label {
        writing-mode         : tb;

        padding-inline-start : 1em;
    }
}

.b-sch-current-time {
    pointer-events : none;
}

.b-animating .b-sch-timerange {
    transition : inset 0.3s, width 0.3s, height 0.3s;
}

// Sub grid time range styling
.b-timeline-subgrid {

    .b-sch-timerange {
        height      : 100%;
        align-items : flex-start;
        min-width   : 1px;
        color       : $range-color;
        font-size   : 14px;

        &:not(.b-sch-line) {
            label {
                overflow : visible; // To be able to measure and rotate

                // If the width is greater than the range width, write vertically
                &.b-vertical {
                    -webkit-writing-mode : vertical-lr;
                    writing-mode         : vertical-lr;
                    -ms-writing-mode     : tb-lr;
                    // TODO RTL??
                }
            }
        }

        // No support for icons shown in lines the timeline body
        &.b-sch-line {
            width    : 1px;
            overflow : visible;

            &:before {
                display : none;
            }
        }
    }

    .b-sch-range {
        background-color : $range-background-color;
        pointer-events   : none;
    }

    div.b-sch-line {
        border-inline-start : $line-width $line-style $line-color;
        color               : #fff;
        padding             : 0;
        z-index             : $line-zindex;

        label {
            background-color : $line-color;
            padding          : 0.3em;
            white-space      : nowrap;
        }
    }

    div.b-sch-current-time {
        border-inline-start-color : $current-time-color;

        label {
            background-color : $current-time-color;
        }
    }
}

.b-timelinebase:not(.b-sch-vertical) .b-timeline-subgrid .b-sch-line {
    flex-direction  : column;
    justify-content : flex-start;

    label {
        margin-top : 0.5em;
    }
}


.b-sch-vertical {
    .b-sch-timerange {
        inset-inline-start : auto;
        inset-block-start  : 0;
        justify-content    : flex-start;

        label {
            -webkit-writing-mode : vertical-lr;
            writing-mode         : vertical-lr;
            -ms-writing-mode     : tb-lr;
            transform            : rotate(180deg);

            // TODO RTL??
        }

        &.b-over-resize-handle {
            cursor : ns-resize;
        }
    }

    .b-timeline-subgrid {
        .b-sch-timerange {
            height : auto;
            width  : inherit;
        }

        .b-sch-line {
            border-top : $line-width $line-style $line-color;
            color      : #fff;
            padding    : 0;
            height     : 1px;

            label {
                background-color : $line-color;
                padding          : 0.3em;
                white-space      : nowrap;
            }
        }
    }
}

.b-timeranges-header-canvas {
    .b-sch-timerange {
        pointer-events : all;
    }
}

// Header time range styling, horizontal mode
.b-grid-header {
    .b-sch-timerange {
        flex-direction   : row;
        justify-content  : center;
        bottom           : 0;
        background-color : $range-header-background-color;
        color            : $range-header-color;
        line-height      : 1;
        padding          : .5em 0;

        &.b-sch-line {
            padding          : .5em;
            background-color : $line-color;
            z-index          : $line-zindex; // In front of zones in header
        }

        &.b-sch-current-time {
            background-color : $current-time-color;
        }

        @include user-select-none();

        &:before {
            margin-inline-end : 0.5em;
        }

        label {
            padding : 0;
        }
    }

    &.b-sch-timeaxiscolumn-levels-1 .b-sch-timerange {
        height : 100%;
    }

    &.b-sch-timeaxiscolumn-levels-2 .b-sch-timerange {
        height : 50%;
    }

    &.b-sch-timeaxiscolumn-levels-3 .b-sch-timerange {
        height : math.div(100%, 3);
    }

    &.b-sch-timeaxiscolumn-levels-4 .b-sch-timerange {
        height : 25%;
    }

    &.b-sch-timeaxiscolumn-levels-5 .b-sch-timerange {
        height : 20%;
    }

    .b-sch-line {
        border-bottom-right-radius : 5px;
        border-top-right-radius    : 5px;

        // Flip rounded corners to be at RTL end
        &.b-rtl {
            border-bottom-right-radius : 0;
            border-top-right-radius    : 0;
            border-bottom-left-radius  : 5px;
            border-top-left-radius     : 5px;
        }

        &.b-sch-timerange-with-headerlabel label {
            inset-inline-start : -$line-width;
        }
    }
}

.b-verticaltimeaxiscolumn {
    .b-sch-timerange {
        flex-direction   : column;
        justify-content  : center;
        inset-inline-end : 0;
        background-color : $range-header-background-color;
        color            : $range-header-color;
        width            : 2em;
        @include user-select-none();

        &:before {
            margin-bottom : 0.5em;
        }
    }

    .b-sch-line {
        border-bottom-left-radius  : 5px; // RTL OK
        border-bottom-right-radius : 5px; // RTL OK

        &.b-sch-timerange-with-headerlabel label {
            inset-block-start : -$line-width;
        }
    }
}


// Hide icon in body if we are showing header elements
.b-sch-timeranges-with-headerelements {
    .b-timeline-subgrid {
        .b-sch-range:before {
            display : none;
        }
    }
}

.b-dragging-timerange .b-sch-timerange:not(.b-dragging) {
    pointer-events : none;
}

// Hide time range labels in locked rows clone
.b-locked-rows-clone .b-sch-timerange label {
    display : none;
}
