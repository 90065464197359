@import '../../variables';

.b-percent-bar-outer {
    flex          : 1;
    height        : 30%;
    max-height    : 0.8em;
    background    : $percent-bar-background;
    border-radius : 1em;
    overflow      : clip;
}

.b-percent-bar {
    // For vertically aligning the text
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    height          : 100%;
    max-width       : 100%;
    padding         : 0 .2em;
    background      : $percent-bar-fill;
    color           : $percent-bar-color;
    font-size       : .7em;
    text-align      : end;
    transition      : width 0.5s, padding .5s, background-color .5s;

    &.b-zero {
        padding     : 0;
        text-indent : .2em;
    }

    &.b-low {
        background : $percent-bar-low-fill;
        color      : $percent-bar-low-color;

        span {
            left          : 100%;
            margin-block  : 0;
            margin-inline : 5px 0;
        }
    }

    span {
        position      : relative;
        display       : inline-block;
        margin-block  : 0;
        margin-inline : 0 5px;
        line-height   : 1em;
    }
}


// Percent done column
.b-percentdone-circle {
    --grid-percent-circle-angle : 0;

    display                     : flex;
    align-items                 : center;
    justify-content             : center;
    margin                      : 0 auto;
    max-height                  : 3em;
    max-width                   : 3em;

    border-radius               : 50%;
    background-color            : $percentcircle-background-color;
    background-image            : conic-gradient($percentcircle-done-color 0 var(--grid-percent-circle-angle), transparent var(--grid-percent-circle-angle) 1turn);

    // Override gradient for empty / full circles to eliminate visual artifacts
    &.b-full,
    &.b-empty {
        background-image : none;
    }

    &.b-full {
        background-color : $percentcircle-done-color;
    }

    // Center label + fill
    &::after {
        content         : attr(data-value);

        display         : flex;
        align-items     : center;
        justify-content : center;

        width           : $percentcircle-thickness;
        height          : $percentcircle-thickness;

        border-radius   : 50%;
        background      : $percentcircle-label-background;
        font-size       : $percentcircle-label-font-size;
        color           : $percentcircle-label-color;
    }
}


.b-grid-body-container.b-scrolling {
    .b-percent-bar {
        transition : none;
    }
}
