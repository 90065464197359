@import '../variables';
@import '../../core-sass/mixins';

.b-sch-column.b-grid-header {
    padding : 0;
}

.b-grid-header-container .b-sch-timeaxiscolumn {
    @include user-select-none();

    padding           : 0;

    // Override the b-fill-last-column stretching
    flex-grow         : unset !important;
    background-color  : $grid-header-background-color;
    border-inline-end : 0 none;

    // Hover effects only on individual TimeAxis cells.
    .b-sch-header-timeaxis-cell:hover {
        background-color : $grid-header-hover-background-color;
    }
}

.b-horizontaltimeaxis {
    flex-direction : column;
    flex           : 1 0 100%;

    .b-sch-header-row {
        flex : 1 0 2em;

        &.b-sch-header-row-0.b-lowest {
            // Stretch to fill header if there is just one row
            flex : 1;
        }
    }

    .b-sch-header-timeaxis-cell {
        height              : 100%;
        border-inline-start : 1px solid $grid-header-border-color;

        &:hover {
            cursor           : pointer;
            background-color : $grid-header-hover-background-color;
        }

        // https://github.com/bryntum/support/issues/2626
        // Ensure last time axis cell shows text left align if content does not fit
        &.b-last .b-sch-header-text {
            overflow : hidden;
        }
    }
}

// With the sync approach header cells are now absolutely positioned and need to get their height from somewhere
.b-sch-timeaxiscolumn-levels-1 {
    min-height : 1.5em;
}

.b-sch-timeaxiscolumn-levels-2 {
    min-height : 3em;
}

.b-sch-timeaxiscolumn-levels-3 {
    min-height : 4.5em;
}

.b-sch-header-row {
    position : relative;
    contain  : strict;

    &:last-child .b-sch-header-timeaxis-cell {
        border-bottom : none;
    }
}

.b-sch-header-timeaxis-cell {
    display         : flex;
    justify-content : center;
    position        : absolute;

    color           : $grid-header-text-color;
    border-bottom   : 1px solid $grid-header-border-color;
    overflow        : hidden;
    transition      : background-color 0.2s;
    font-size       : $grid-header-font-size;
    font-weight     : $grid-header-font-weight;
    padding         : top($timeaxis-cell-header-padding) 0 bottom($timeaxis-cell-header-padding) 0;
    white-space     : nowrap;
    contain         : strict;

    @include user-select-none();

    &.b-align-start,
    &.b-align-end {
        .b-sch-header-text {
            padding-inline-start : left($event-bar-content-margin);
            padding-inline-end   : right($event-bar-content-margin);
        }
    }

    &.b-align-start {
        justify-content : flex-start;
    }

    &.b-align-end {
        justify-content : flex-end;
    }
}

.b-schedulerbase.b-fill-last-column {
    .b-sch-timeaxiscolumn:last-child {
        border-inline-end-color : $grid-header-border-color;
    }
}

.b-sch-header-timeaxis-cell[data-tick-index="0"] {
    border-inline-start:0;
}


// Adjust header borders to be able to have time range header elements placed above the border
.b-timelinebase:not(.b-sch-vertical) {
    .b-grid-header-container {
        border-bottom : 0;
    }

    .b-header:not(.b-grid-header-scroller-normal) .b-grid-headers,
    .b-horizontaltimeaxis {
        border-bottom : 1px solid $grid-header-border-color;
    }
}

