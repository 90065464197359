@import '../../variables';

.b-buttongroup {
    // In case it contains buttons with badges
    overflow    : visible;
    flex-shrink : 0;

    &.b-content-element {
        // Want it to shrink-wrap its contents
        display   : inline-flex;
        flex-flow : row nowrap;
        gap       : 0;

        &.b-rotate-vertical {
            flex-direction : column;
        }

        &.b-invalid {
            border-style : solid;
            border-width : 1px;
            border-color : $field-invalid-border-color;
        }
    }

    // Displayed in a toolbar, match standalone button
    .b-toolbar & {
        font-size : $button-toolbar-font-size;
    }

    .b-button {
        // To override any margin added by demo styling, convenient to have
        margin : 0;

        &:not(.b-pressed) {
            box-shadow : none !important;
        }

        &.b-pressed,
        &:active {
            z-index : 2;
        }
    }

    &.b-columned.b-content-element {
        flex      : 1;
        flex-wrap : wrap;

        .b-button {
            margin-inline-start : 0;
        }
    }

    &.b-no-gap {
        .b-button:not(.b-first-visible-child) {
            margin-inline-start : $buttongroup-button-left-margin;
        }

        .b-button:not(.b-first-visible-child):not(.b-last-visible-child) {
            border-radius : 0;
        }

        .b-button.b-first-visible-child:not(.b-last-visible-child) {
            border-top-right-radius    : 0;
            border-bottom-right-radius : 0;
        }

        .b-button.b-last-visible-child:not(.b-first-visible-child) {
            border-top-left-radius    : 0;
            border-bottom-left-radius : 0;
        }

        &.b-rtl {
            .b-button.b-first-visible-child:not(.b-last-visible-child) {
                border-top-left-radius    : 0;
                border-bottom-left-radius : 0;
            }

            .b-button.b-last-visible-child:not(.b-first-visible-child) {
                border-top-right-radius    : 0;
                border-bottom-right-radius : 0;
            }
        }
    }
}
