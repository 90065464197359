@import '../../variables';

.b-group-summary {
    // No overflow for title in collapsed group header which is also showing summaries.
    .b-grid-group-collapsed.b-header-summary {
        .b-grid-cell.b-group-title {
            overflow : hidden;
        }
    }


    .b-grid-row.b-group-footer {
        .b-grid-cell {
            border-inline-end-color : transparent;
            background-color        : $grid-groupsummary-background-color;
        }

        td {
            padding : .25em 0;
        }

        .b-grid-summary-label {
            padding-inline-end : 1em;
        }

        .b-grid-summary-value {
            width : 100%;
        }
    }
}
