@import '../../variables';

.b-summary-wrap {
    flex                  : 0 0 100%;
    display               : grid;
    gap                   : .1em 1em;
    grid-template-columns : auto 1fr;
    align-items           : center;


    .b-grid-summary-value {
        overflow      : hidden;
        text-overflow : ellipsis;

        // Spans two columns if there's no label
        &.b-nolabel {
            grid-column : span 2/span 2;
        }
    }
}

.b-summary-disabled .b-grid-footer-container {
    display : none;
}
