@import '../variables';
@import '../../scheduler-sass/view/eventrendering';
@import '../../schedulerpro-sass/feature/eventsegments';

// base styles are defined in scheduler

@include eventrendering(
        $widget-name                  : 'gantt',
        $entity-name                  : 'task',
        $entity-zindex                : $event-zindex,
        $entity-font-weight           : $event-font-weight,
        $entity-font-size             : $event-font-size,
        $entity-default-transition    : $event-default-transition,
        $interacting-entity-zindex    : $interacting-event-zindex,
        $entity-bar-padding-left      : 0,
        $entity-selected-zindex       : $selected-event-zindex,
        $entity-selected-font-weight  : $event-selected-font-weight,
        $entity-milestone-color       : $gantt-milestone-background-color,
        $entity-milestone-zindex      : $milestone-zindex,
        $entity-labels-color          : $event-labels-color,
        $selected-entity-labels-color : $selected-event-labels-color
);

@include segmented-eventrendering(
        $entity-name                 : 'task',
        $prefix                      : 'gantt',
        $entity-bar-background-color : $gantt-task-background-color
);

.b-gantt-task-wrap {
    display               : grid;
    // Gantt tasks are all the same height
    height                : 1em;

    grid-template-areas   :
            "top    top    top   "
            "before body   after "
            "void1  rollup void2"
            "bottom bottom bottom";

    grid-template-columns : 0 1fr 0;
    grid-template-rows    : min-content minmax(0, 100%) min-content min-content;

    &.b-inactive {
        .b-gantt-task {
            filter  : $gantt-inactive-task-filter;
            opacity : $gantt-inactive-task-opacity;
        }

        .b-sch-label {
            color : $gantt-inactive-task-label-color;
        }
    }

    &.b-gantt-task-parent {
        .b-gantt-task {
            align-self : center;
            height     : 100%;
        }

        .b-active .b-gantt-task {
            // Cannot use outline because of the clip-path used to shape parents
            outline : none;
        }
    }

    &.b-milestone-wrap {
        margin-inline-start : 0;
    }

}

.b-gantt-task {
    border-radius : $gantt-task-border-radius;
    align-self    : stretch;
    width         : 100%; // Scheduler uses 100% - 1px to give a gap between adjacent events, not needed for Gantt
    grid-area     : body;
}

// Text and icon in task
.b-gantt-task-content {
    padding       : 0 .4em;
    font-size     : .8em;
    width         : 100%;
    overflow      : hidden;
    text-overflow : ellipsis;
    // Above percent bar
    z-index       : 1;
}

.b-gantt-task-parent:not(.b-milestone-wrap) .b-gantt-task {
    // To show the start/end arrows
    overflow      : visible;

    border-radius : $gantt-task-border-radius $gantt-task-border-radius 0 0;

    flex          : 1 1 0;
    max-height    : 2em;
    min-height    : 1.3em; // Parent tasks look bad when smaller than this due to hardcoded triangle size

    clip-path     : polygon(
                    -20px 0,
                    calc(100% + 20px) 0,
                    calc(100% + 20px) 100%,
                    100% 100%,
                    calc(100% - 8px) calc(100% - 8px),
                    8px calc(100% - 8px),
                    0 100%,
                    -20px 100%
    );
}

// Focus outline for parent tasks, in wrapper because of clip-path
.b-using-keyboard .b-gantt-task-wrap.b-gantt-task-parent.b-active::before {
    content        : '';
    position       : absolute;
    width          : 100%;
    height         : 100%;
    outline        : $grid-cell-focused-outline-width $grid-cell-focused-outline-style $grid-cell-focused-outline-color;
    outline-offset : 3px;
}

.b-gantt .b-gantt-task-wrap {
    &.b-over-resize-handle .b-gantt-task {
        cursor : ew-resize !important;
    }

    .b-sch-event-segment.b-over-resize-handle {
        cursor : ew-resize !important;
    }
}


.b-gantt.b-resizing-event * {
    @include user-select-none();
    cursor : ew-resize !important;
}

.b-new-column-column .b-grid-header-text {
    display : none;
}

.b-new-column-combo .b-field-inner {
    background-color : transparent;
    border           : 0 none;

    input {
        padding : 0;
    }
}

.b-gantt .b-grid-subgrid-locked .b-grid-row.b-inactive .b-grid-cell {
    // do not strikeout these columns
    &[data-column="name"], &[data-column="startDate"], &[data-column="endDate"], &[data-column="earlyStartDate"], &[data-column="earlyEndDate"],
    &[data-column="lateStartDate"], &[data-column="lateEndDate"], &[data-column="wbsValue"], &[data-column="fullDuration"], &[data-column="fullEffort"],
    &[data-column="schedulingMode"], &[data-column="constraintType"], &[data-column="calendar"], &[data-column="deadline"],
    &[data-column="totalSlack"], &[data-column="note"], &[data-column="predecessors"], &[data-column="successors"] {
        text-decoration : $gantt-inactive-task-cell-text-decoration;
        color           : $gantt-inactive-task-cell-color;
    }
}

// Gantt default coloring
.b-sch-color-none.b-gantt-task-wrap {
    &,
    > .b-gantt-task,
    > .b-gantt-task > .b-sch-event-segments > .b-gantt-task.b-sch-color-none {
        --event-background-color : #{$gantt-task-background-color};
        border-color             : #{$gantt-task-background-color};

        &.b-milestone {
            --event-background-color : #{$gantt-milestone-background-color};
            border-color             : #{$gantt-milestone-background-color};
        }
    }

    &.b-gantt-task-parent:not(.b-milestone-wrap) .b-gantt-task {
        --event-background-color : #{$gantt-parent-background-color};
        border-color             : #{$gantt-parent-background-color};
    }
}
