@import '../schedulerpro-sass/variables';

// region PercentBar

$percent-bar-background-color                               : scale_color($green, $lightness : 20%) !default;
$percent-bar-hover-background-color                         : scale-color($green, $lightness : -45%) !default;
$percent-bar-parent-background-color                        : $blue !default;
$percent-bar-parent-hover-background-color                  : scale_color($blue, $lightness : -45%) !default;

// endregion

$gantt-task-background-color                                : scale_color($green, $lightness : 50%) !default;
$gantt-task-hover-background-color                          : scale_color($green, $lightness : -15%) !default;

$gantt-parent-background-color                              : $schedulerpro-parent-background-color !default;

$gantt-milestone-background-color                           : $gantt-parent-background-color !default;

$gantt-task-border-radius                                   : null !default;

$gantt-task-percent-background-color                        : rgba(0, 0, 0, 0.4) !default;
$gantt-task-percent-mix-blend-mode                          : overlay !default;

$gantt-inactive-task-cell-color                             : #aaa !default;
$gantt-inactive-task-cell-text-decoration                   : line-through !default;

$gantt-inactive-task-label-color                            : scale_color($event-labels-color, $lightness : 50%) !default;

$gantt-inactive-task-filter                                 : grayscale(1) !default;
$gantt-inactive-task-opacity                                : 0.5 !default;

// critical paths styling

// The color that is mixed into critical tasks/dependency colors
$gantt-critical-paths-base-color                            : red !default;
// The percentage used to mix $gantt-critical-paths-base-color into critical tasks/dependency colors
$gantt-critical-paths-base-color-mix-percentage             : 60% !default;

// The color that is mixed into critical summary tasks background color
$gantt-critical-parent-background-base-color                : $gantt-critical-paths-base-color !default;
// The color that is mixed into critical tasks background color
$gantt-critical-task-background-base-color                  : $gantt-critical-paths-base-color !default;
// The color that is mixed into critical dependencies stroke color
$gantt-critical-dependency-stroke-base-color                : $gantt-critical-paths-base-color !default;

$gantt-critical-parent-background-base-color-mix-percentage : $gantt-critical-paths-base-color-mix-percentage !default;
$gantt-critical-task-background-base-color-mix-percentage   : $gantt-critical-paths-base-color-mix-percentage !default;
$gantt-critical-dependency-stroke-base-color-mix-percentage : $gantt-critical-paths-base-color-mix-percentage !default;

$gantt-critical-parent-background-color                     : mix($gantt-critical-parent-background-base-color, $gantt-parent-background-color, $gantt-critical-parent-background-base-color-mix-percentage) !default;

$gantt-critical-task-background-color                       : mix($gantt-critical-task-background-base-color, $gantt-task-background-color, $gantt-critical-task-background-base-color-mix-percentage) !default;

$gantt-critical-dependency-stroke                           : mix($gantt-critical-dependency-stroke-base-color, $dependency-color, $gantt-critical-dependency-stroke-base-color-mix-percentage);

// baseline styling

$gantt-baseline-wrap-size                                   : .6em !default;
$gantt-baseline-flex                                        : 0.5 !default;
$gantt-baseline-background-color                            : #ddd !default;
$gantt-baseline-border-width                                : 0 !default;
$gantt-baseline-milestone-border-color                      : $grid-background-color !default;
$gantt-baseline-border-color                                : orange !default;
$gantt-baseline-animate-duration                            : 0.3s !default;
$gantt-baseline-margin-top                                  : 1px !default;

$gantt-rollup-color                                         : $event-bar-background-color;
$gantt-rollup-milestone-size                                : 0.275em !default;
$gantt-rollup-milestone-background-color                    : $gantt-parent-background-color !default;

$parent-area-background                                     : #88888825 !default;

$indicator-dates-color                                      : #ccc !default;
$indicator-dates-hover-color                                : #999 !default;
$indicator-at-bottom-size                                   : 6px !default;
$indicator-constraint-size                                  : 5px !default;
$indicator-arrow-size                                       : 4px !default;
$indicator-at-bottom-top                                    : calc(1em + 4px) !default;

$scrollbutton-background-color                              : $grid-background-color !default;
$scrollbutton-color                                         : darken($grid-cell-border-color, 10%) !default;
$scrollbutton-hover-color                                   : darken($grid-cell-border-color, 30%) !default;
