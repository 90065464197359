@import '../variables';

.b-percentbar {
    .b-sch-event-wrap {
        overflow : visible;

        .b-sch-event {
            overflow : visible;
        }
    }
}

.b-task-percent-bar-resizing-task {
    .b-gantt-task {
        overflow : visible;
    }
}

.b-task-percent-bar-outer {
    position      : absolute;
    left          : 0;
    height        : 100%;
    width         : 100%;
    overflow      : hidden;
    transition    : background-color 0.2s;
    border-radius : inherit;

    .b-sch-style-rounded .b-sch-event.b-sch-event-selected:hover &,
    .b-sch-style-rounded .b-sch-event-selected & {
        background-color : rgba(255, 255, 255, 0.4);
    }
}


.b-task-percent-bar {
    background-color          : $percent-bar-background-color;
    border-start-start-radius : inherit;
    border-end-start-radius   : inherit;

    .b-sch-horizontal &,
    .b-gantt & {
        height     : 100%;
        max-width  : 100%;
        text-align : right;
    }

    .b-sch-vertical & {
        width      : 100%;
        max-height : 100%;
    }

    z-index                   : $percent-bar-z-index;
    box-sizing                : content-box;
    display                   : flex;
    justify-content           : flex-end;
    align-items               : flex-end;

    // Always transition color, for hover etc.
    transition                : color 0.2s, background-color 0.2s, opacity 0.2s;

    // Only transition width while animating changes
    .b-animating & {
        transition : color 0.2s, width 0.2s, height 0.2s;
    }

    // if outside page uses line-height on BODY tag it will displace the % text
    line-height               : initial;

    // No transition on width when resizing
    .b-task-percent-bar-resizing-task & {
        transition : background-color 0.2s;
    }

    .b-sch-event-selected & {
        background-color : $percent-bar-selected-background-color;
    }

    .b-sch-style-rounded & {
        background-color : currentColor;
        mix-blend-mode   : overlay;
        opacity          : .5;
    }

    .b-sch-style-rounded .b-sch-event:hover & {
        mix-blend-mode : darken;
        opacity        : .4;
    }

    .b-sch-style-rounded .b-sch-event.b-sch-event-selected:hover &,
    .b-sch-style-rounded .b-sch-event-selected & {
        background-color : rgba(0, 0, 0, 0.4);
        mix-blend-mode   : darken;
        opacity          : .4;
    }
}

.b-task-percent-bar-handle {
    position           : absolute;
    width              : 0;
    height             : 0;
    display            : flex;
    justify-content    : center;

    .b-sch-horizontal &,
    .b-gantt & {
        cursor              : ew-resize !important;
        bottom              : 0;
        margin-inline-start : -5px;
    }

    .b-gantt-task-parent & {
        bottom : 8px;
    }

    .b-sch-vertical & {
        cursor     : ns-resize !important;
        left       : 0;
        margin-top : -4px;
        transform  : rotate(90deg);
    }

    z-index            : 1000;
    border-color       : #fff;
    border-right-color : transparent;
    border-left-color  : transparent;
    border-width       : 0 5px 6px 5px;
    border-style       : solid;
    opacity            : 1;

    .b-sch-horizontal .b-sch-event & {
        margin-inline-start : -8px;
        border-width        : 0 6px 7px 7px;
    }

    .b-sch-vertical .b-sch-event & {
        margin-inline-start : -3px;
        border-width        : 0 6px 7px 7px;
    }

    .b-sch-event.b-sch-event-selected & {
        z-index : 1000;
    }

    .b-sch-style-rounded & {
        border-block-color : #333;
    }
}

// Display xx%
.b-task-percent-bar-handle::after {
    margin-top : 10px;
    font-size  : .8em;
    color      : $grid-cell-color;
}

.b-task-percent-bar-show-percentage:not(.b-resizing-event):not(.b-dragging-event) {
    &.b-task-percent-bar-resizing-task,
    .b-sch-event-hover {
        .b-task-percent-bar-handle::after {
            position : absolute;
            content  : attr(data-percent) "%";
        }
    }
}

.b-sch-vertical .b-task-percent-bar-handle::after {
    margin-top : -2em;
    transform  : rotate(-90deg);
}

.b-task-percent-bar-resizing-task,
.b-task-percent-bar-resizing-event {
    .b-sch-event,
    .b-gantt-task {
        @include user-select-none();
        cursor : ew-resize !important;
    }
}

.b-task-percent-bar-drag-disabled .b-task-percent-bar-handle,
.b-readonly .b-task-percent-bar-handle {
    display : none !important;
}
