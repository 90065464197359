.b-resourcefilter {
    .b-list-item {
        border        : 0 none;
        border-radius : 0;
    }

    .b-selected-icon {
        visibility : visible !important;

        &:before {
            content : "\f0c8";
            opacity : 0.6;
        }
    }

    .b-selected .b-selected-icon:before {
        content : "\f14a";
        opacity : 1;
    }
}
