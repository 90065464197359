@mixin user-select($value) {
    // Required for Safari support
    -webkit-user-select : $value;
    user-select         : $value;
}

@mixin user-select-none() {
    @include user-select(none);
}

@mixin user-select-all() {
    @include user-select(all);
}

@mixin user-select-text() {
    @include user-select(text);
}

@mixin fade-in {
    opacity                   : 1;
    animation-name            : fadeInOpacity;
    animation-iteration-count : 1;
    animation-timing-function : ease-in;
    animation-duration        : 0.5s;
}

@keyframes fadeInOpacity {
    0% {
        opacity : 0;
    }
    100% {
        opacity : 1;
    }
}
