@use "sass:math";
@import "../../variables";

// Hidden by default
.b-row-drop-indicator {
    display        : none;
    pointer-events : none;
}

.b-row-reordering {
    // A full-width line. It's translated to the row top, and it's top position makes
    // it position correctly between rows.
    .b-row-drop-indicator {
        position         : absolute;
        display          : block;
        left             : 0;
        top              : #{math.div(-$rowreorder-indicator-width, 2)};
        width            : 100%;
        height           : $rowreorder-indicator-width;
        background-color : $rowreorder-indicator-color;
        z-index          : 2000;

        &.b-drag-invalid {
            background-color : $rowreorder-indicator-invalid-color;
        }
    }

    .b-row-reorder-proxy.b-dragging {
        transition : margin-top 0.2s, opacity 0.2s;
        background : transparent;
        opacity    : 0.5;

        .b-grid-row {
            width : 100%;
        }

        & .b-grid-row,
        & .b-row-dragging-multiple {
            transition : transform 0.2s, background-color 0.2s;
            box-shadow : $rowreorder-box-shadow;
            background : $rowreorder-row-background;
        }

        & .b-row-dragging-multiple {
            top     : -0.3em;
            left    : 0.3em;
            z-index : -1;
        }

        &.b-drag-invalid .b-grid-row {
            border     : $rowreorder-indicator-invalid-border;
            background : transparentize($rowreorder-indicator-invalid-color, .8);
        }
    }

    // Must move above the .b-grid-splitter element during drag
    .b-grid-body-container {
        z-index : 4;
    }

    .b-drag-original {
        opacity : 0.3;
    }

    .b-grid-row {
        &.b-grid-group-collapsed.b-row-reordering-target {
            background-color : $grid-row-hover-background-color;
        }
    }

    .b-grid-body-container .b-grid-subgrid .b-row-reordering-target {
        box-shadow : 0 1px 0 0 $rowreorder-indicator-color inset, 0 -1px 0 0 $rowreorder-indicator-color inset;
        z-index    : 9999;
    }
}


.b-row-reorder-grip {
    // The first column now must carry two child nodes: The gripper and the real content.
    // Use grid to arrange these.
    display               : grid;
    grid-template-columns : auto 1fr;

    &.b-grid-cell-align-end {
        justify-items : end;

        &:before {
            margin-inline-end : auto;
        }
    }

    &.b-grid-cell-align-right {
        justify-items : right;
    }

    &.b-grid-cell-align-center {
        justify-items : center;
    }

    &:before {
        display                 : flex;
        -webkit-font-smoothing  : antialiased;
        -moz-osx-font-smoothing : grayscale;
        font-family             : $icon-font;
        align-self              : stretch;
        align-items             : center;
        content                 : "#{ $fa-var-grip-vertical }";
        cursor                  : move;
        padding-inline          : $grid-cell-inline-padding * 1.7;
        // Want to keep padding to have larger hit area, but don't want it to move further into the cell.
        // Also want to keep cell padding to make sure cells and headers align, thus compensating with margin
        // "auto" to force handle to left side in a right aligned column
        margin-inline-start     : -$grid-cell-inline-padding;
        font-size               : 0.8em;
    }
}

.b-row-reorder-proxy {
    .b-row-proxy-copy {
        display            : none;
        height             : 1em;
        width              : 1em;
        align-items        : center;
        justify-content    : center;
        position           : absolute;
        inset-inline-start : -.5em;
        top                : -.5em;
        box-shadow         : 1px 1px 5px $grid-cell-border-color;
        border-radius      : 50%;
        padding            : .75em;
        background         : $grid-background-color;
        z-index            : 20000;
    }
}

.b-row-reorder-proxy.b-drag-copy {
    .b-row-proxy-copy {
        display : flex;
    }
}
