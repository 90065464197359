.b-textareafield,
.b-textareapickerfield {
    textarea {
        padding    : $textareafield-input-padding;
        align-self : stretch;
    }
}

.b-textareapickerfield-picker {
    display : flex;
    flex       : 1;
    min-height : 10em;

    &:focus {
        outline      : none;
        border-color : $widget-highlight-color;
    }
}
