@import '../variables';

.b-scrollbuttons {
    --scrollbutton-color            : #{$scrollbutton-color};
    --scrollbutton-background-color : #{$scrollbutton-background-color};
    --scrollbutton-hover-color      : #{$scrollbutton-hover-color};

    .b-timeaxissubgrid {
        .b-grid-row,
        .b-grid-cell {
            overflow : unset;
            z-index  : $scrollbuttons-zindex;
        }

        .b-scroll-buttons-container {
            height         : 100%;
            display        : flex;
            width          : 100%;
            pointer-events : none;
            align-items    : center;
        }

        .b-scroll-button-wrap {
            position        : sticky;
            display         : flex;
            flex-direction  : row;
            align-items     : baseline;
            justify-content : center;
            border-radius   : .3em;
            gap             : .5em;
            border          : 1px solid var(--scrollbutton-color);
            padding         : 0.1em 0.5em;
            pointer-events  : all;
            cursor          : pointer;
            background      : var(--scrollbutton-background-color);
            transition      : color .2s, background-color .2s;
            color           : var(--scrollbutton-color);
            min-width       : 3.1em;
            font-size       : .8em;

            &:hover {
                --scrollbutton-color : var(--scrollbutton-hover-color);
            }

            label {
                font-size   : .9em;
                font-weight : 500;
            }

            &.b-scroll-button-hidden {
                visibility: hidden;
            }

            &.b-scroll-backward {
                inset-inline-start : 1em;
            }

            &.b-scroll-forward {
                inset-inline-start : 100%;
                translate          : calc(-100% - 1em);
                margin-inline-end  : -100%;
            }
        }

        &.b-rtl .b-scroll-button-wrap.b-scroll-forward {
            translate: calc(100% + 1em);
        }
    }
}
