@import '../../variables';

.b-fade {
    animation-name            : b-fade;
    animation-delay           : 0s;
    animation-direction       : normal;
    animation-duration        : 1s;
    animation-iteration-count : 2;
    animation-timing-function : cubic-bezier(.4, 0, .6, 1);
}

@keyframes b-fade {
    50% {
        opacity : 0.4;
    }
}

.b-calendareditorweektab {

    .b-divider {
        margin-bottom : 0;
    }

    [data-ref="weekContainer"] {

        .b-calendareditoravailabilityrangecontainer {
            flex : 1 0 100%;
        }

        &.b-copying {
            .b-availability-day.b-copied {
                button.b-day-copy {
                    display : inherit;
                }

                button.b-day-paste {
                    display : none;
                }
            }

            .b-availability-day:not(.b-copied) {
                button.b-day-copy {
                    display : none;
                }

                button.b-day-paste {
                    display : inherit;
                }
            }
        }

        .b-availability-day {
            margin-bottom   : .5em;
            justify-content : start;

            > div:first-child {
                justify-content : space-between;
                padding-top     : .9em;
            }

            .b-slidetoggle {
                height : 1.5em;
            }

            button.b-day-copy {
                display : inherit;

                &.b-pressed {
                    color : $blue;
                }
            }

            button.b-day-paste {
                display : none;
            }

            .b-availability-day-actions {
                margin-top : .2em;
                align-self : start;
            }

            // TODO check if this is needed
            &.b-margin-top {
                margin-top : .5em;
            }

            &.b-disabled {
                .b-availability-range, .b-availability-day-actions {
                    display : none;
                }
            }
        }
    }
}
