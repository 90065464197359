@import '../variables';

.b-resource-info {
    flex           : 1;
    height         : 100%;

    display        : flex;
    flex-direction : row;
    align-items    : center;
    overflow       : hidden;

    dl {
        overflow : hidden;
        gap      : .2em;
    }

    dt {
        font-weight   : 600;
        overflow      : hidden;
        text-overflow : ellipsis;
    }

    &.b-no-avatar dt {
        margin-inline-start : 0.6em;
    }

    dd {
        margin        : 0;
        font-size     : .8em;
        color         : $resourceinfo-role-color;
        overflow      : hidden;
        text-overflow : ellipsis;
    }

    .b-resource-avatar {
        margin-inline-end : .5em;
    }
}
