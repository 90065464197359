@import '../variables';

.b-calendarfield {

    .b-field-inner {
        .b-widget.b-fieldtrigger[data-ref="calendarEditor"] {
            border-left-style: solid;
            border-left-width: 1px;
            border-left-color: $field-border-color;
            height: 2.9em;
            padding-inline-start: 0.6em;
        }
    }

    &.b-empty {
        input {
            color : $field-placeholder-color;
        }
    }
}
