
.b-scrollbuttons {
    --scrollbutton-color            : #{$scrollbutton-color};
    --scrollbutton-background-color : #{$scrollbutton-background-color};
    --scrollbutton-hover-color      : #{$scrollbutton-hover-color};

    .b-timeaxissubgrid {
        .b-grid-row,
        .b-grid-cell {
            z-index  : 2;
            overflow : unset;
        }

        .b-column-lines-canvas {
            z-index : 0;
        }

        svg.b-sch-dependencies-canvas {
            z-index : 1;
        }

        .b-scroll-buttons-container {
            height         : 100%;
            display        : flex;
            width          : 100%;
            pointer-events : none;
        }

        .b-scroll-buttons-content {
            position        : sticky;
            height          : 100%;
            display         : flex;
            flex-direction  : column;
            justify-content : center;

            label {
                font-size   : .9em;
                font-weight : 500;
            }

            &.b-scroll-forward {
                inset-inline-start : calc(100% - 4em);
            }

            &.b-scroll-backward {
                inset-inline-start : 1em;
            }
        }

        i.b-scroll-button {
            display         : flex;
            justify-content : center;
            align-items     : center;

            padding         : 0.5em;
            width           : 2em;
            height          : 2em;

            width           : 1.8em;
            height          : 1.8em;
            margin          : 0.1em 0;
            background      : var(--scrollbutton-background-color);
            border-radius   : 50%;
            transition      : all .2s !important;
            cursor          : pointer;
            color           : var(--scrollbutton-color);
            border          : 2px solid var(--scrollbutton-color);
            pointer-events  : all;

            &:hover {
                --scrollbutton-color : var(--scrollbutton-hover-color);
            }

            &.b-task-visible {
                opacity        : 0;
                pointer-events : none;
            }
        }
    }
}
