@import 'variables';

//region Colors

// for usage on other elements, such as in combos or in grid columns
.b-sch-red {
    background-color : $event-color-red;
}

.b-sch-foreground-red {
    color : $event-color-red;
}

.b-sch-pink {
    background-color : $event-color-pink;
}

.b-sch-foreground-pink {
    color : $event-color-pink;
}

.b-sch-purple {
    background-color : $event-color-purple;
}

.b-sch-foreground-purple {
    color : $event-color-purple;
}

.b-sch-magenta {
    background-color : $event-color-magenta;
}

.b-sch-foreground-magenta {
    color : $event-color-magenta;
}

.b-sch-violet {
    background-color : $event-color-violet;
}

.b-sch-foreground-violet {
    color : $event-color-violet;
}

.b-sch-indigo {
    background-color : $event-color-indigo;
}

.b-sch-foreground-indigo {
    color : $event-color-indigo;
}

.b-sch-blue {
    background-color : $event-color-blue;
}

.b-sch-foreground-blue {
    color : $event-color-blue;
}

.b-sch-cyan {
    background-color : $event-color-cyan;
}

.b-sch-foreground-cyan {
    color : $event-color-cyan;
}

.b-sch-teal {
    background-color : $event-color-teal;
}

.b-sch-foreground-teal {
    color : $event-color-teal;
}

.b-sch-green {
    background-color : $event-color-green;
}

.b-sch-foreground-green {
    color : $event-color-green;
}

.b-sch-lime {
    background-color : $event-color-lime;
}

.b-sch-foreground-lime {
    color : $event-color-lime;
}

.b-sch-gantt-green {
    background-color : $event-color-gantt-green;
}

.b-sch-foreground-gantt-green {
    color : $event-color-gantt-green;
}

.b-sch-yellow {
    background-color : $event-color-yellow;
}

.b-sch-foreground-yellow {
    color : $event-color-yellow;
}

.b-sch-orange {
    background-color : $event-color-orange;
}

.b-sch-foreground-orange {
    color : $event-color-orange;
}

.b-sch-deep-orange {
    background-color : $event-color-deep-orange;
}

.b-sch-foreground-deep-orange {
    color : $event-color-deep-orange;
}

.b-sch-gray {
    background-color : $event-color-gray;
}

.b-sch-foreground-gray {
    color : $event-color-gray;
}

.b-sch-light-gray {
    background-color : $event-color-light-gray;
}

.b-sch-foreground-light-gray {
    color : $event-color-light-gray;
}

.b-sch-foreground-black {
    color : $event-color-black;
}

//endregion

//region Color vars

.b-sch-color-red {
    --event-primary-color-h : #{hue($event-color-red)};
    --event-primary-color-s : #{saturation($event-color-red)};
    --event-primary-color-l : #{lightness($event-color-red)};
}

.b-sch-color-pink {
    --event-primary-color-h : #{hue($event-color-pink)};
    --event-primary-color-s : #{saturation($event-color-pink)};
    --event-primary-color-l : #{lightness($event-color-pink)};
}

.b-sch-color-purple {
    --event-primary-color-h : #{hue($event-color-purple)};
    --event-primary-color-s : #{saturation($event-color-purple)};
    --event-primary-color-l : #{lightness($event-color-purple)};
}

.b-sch-color-violet {
    --event-primary-color-h : #{hue($event-color-violet)};
    --event-primary-color-s : #{saturation($event-color-violet)};
    --event-primary-color-l : #{lightness($event-color-violet)};
}

.b-sch-color-indigo {
    --event-primary-color-h : #{hue($event-color-indigo)};
    --event-primary-color-s : #{saturation($event-color-indigo)};
    --event-primary-color-l : #{lightness($event-color-indigo)};
}

.b-sch-color-blue {
    --event-primary-color-h : #{hue($event-color-blue)};
    --event-primary-color-s : #{saturation($event-color-blue)};
    --event-primary-color-l : #{lightness($event-color-blue)};
}

.b-sch-color-cyan {
    --event-primary-color-h : #{hue($event-color-cyan)};
    --event-primary-color-s : #{saturation($event-color-cyan)};
    --event-primary-color-l : #{lightness($event-color-cyan)};
}

.b-sch-color-magenta {
    --event-primary-color-h : #{hue($event-color-magenta)};
    --event-primary-color-s : #{saturation($event-color-magenta)};
    --event-primary-color-l : #{lightness($event-color-magenta)};
}

.b-sch-color-teal {
    --event-primary-color-h : #{hue($event-color-teal)};
    --event-primary-color-s : #{saturation($event-color-teal)};
    --event-primary-color-l : #{lightness($event-color-teal)};
}

.b-sch-color-green {
    --event-primary-color-h : #{hue($event-color-green)};
    --event-primary-color-s : #{saturation($event-color-green)};
    --event-primary-color-l : #{lightness($event-color-green)};
}

.b-sch-color-lime {
    --event-primary-color-h : #{hue($event-color-lime)};
    --event-primary-color-s : #{saturation($event-color-lime)};
    --event-primary-color-l : #{lightness($event-color-lime)};
}

.b-sch-color-yellow {
    --event-primary-color-h : #{hue($event-color-yellow)};
    --event-primary-color-s : #{saturation($event-color-yellow)};
    --event-primary-color-l : #{lightness($event-color-yellow)};
}

.b-sch-color-orange {
    --event-primary-color-h : #{hue($event-color-orange)};
    --event-primary-color-s : #{saturation($event-color-orange)};
    --event-primary-color-l : #{lightness($event-color-orange)};
}

.b-sch-color-deep-orange {
    --event-primary-color-h : #{hue($event-color-deep-orange)};
    --event-primary-color-s : #{saturation($event-color-deep-orange)};
    --event-primary-color-l : #{lightness($event-color-deep-orange)};
}

.b-sch-color-gray {
    --event-primary-color-h : #{hue($event-color-gray)};
    --event-primary-color-s : #{saturation($event-color-gray)};
    --event-primary-color-l : #{lightness($event-color-gray)};
}

.b-sch-color-light-gray {
    --event-primary-color-h : #{hue($event-color-light-gray)};
    --event-primary-color-s : #{saturation($event-color-light-gray)};
    --event-primary-color-l : #{lightness($event-color-light-gray)};
}

.b-sch-color-gantt-green {
    --event-primary-color-h : #{hue($event-color-gantt-green)};
    --event-primary-color-s : #{saturation($event-color-gantt-green)};
    --event-primary-color-l : #{lightness($event-color-gantt-green)};
}

.b-sch-color-black {
    --event-primary-color-h : #{hue($event-color-black)};
    --event-primary-color-s : #{saturation($event-color-black)};
    --event-primary-color-l : #{lightness($event-color-black)};
}

//endregion

//region Plain

.b-sch-style-interday, // Variant when used with Calendar
.b-sch-style-plain {
    > .b-sch-event,
    > .b-sch-event > .b-sch-event-segments > .b-sch-event { // Segments
        --event-s-factor                : 1;
        --event-l-factor                : 1;

        --event-hover-s-factor          : 1.3;
        --event-hover-l-factor          : 0.6;

        --event-selected-hover-s-factor : 1.4;
        --event-selected-hover-l-factor : 0.55;
    }
}

.b-sch-style-interday {
    .b-sch-event {
        opacity       : 0.8;
        border-radius : 3px;
    }

    .b-sch-event-content {
        font-weight : 400;
    }
}

.b-sch-style-plain.b-sch-custom-color > .b-sch-event {
    &:not(.b-milestone) {
        background-color : currentColor;

        &:hover,
        &.b-sch-event-selected,
        &.b-sch-event-selected:hover {
            background-color : currentColor;
            background-image : linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        }

        > .b-sch-event-content {
            color : #fff;
        }
    }

    &.b-milestone {
        .b-sch-event-content::before {
            background-color : currentColor;
        }

        &:hover,
        &.b-sch-event-selected,
        &.b-sch-event-selected:hover {
            .b-sch-event-content::before {
                background-image : linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
            }
        }
    }
}

.b-sch-layout-milestone-text-position-inside .b-sch-event-wrap.b-milestone-wrap .b-milestone {
    display         : flex;
    justify-content : center;
    align-items     : center;

    label {
        position  : absolute;
        left      : 50%; // RTL OK
        transform : translateX(-50%);
        color     : inherit;
    }
}

//endregion

//region Border

.b-sch-style-border > .b-sch-event,
.b-sch-style-border > .b-sch-event > .b-sch-event-segments > .b-sch-event { // Segments
    --event-border-color            : hsl(
                    var(--event-background-h),
                    var(--event-background-s),
                    calc(var(--event-background-l) * 0.7)
    );
    --event-hover-color             : #fff;

    --event-hover-l-factor          : 0.7;
    --event-selected-hover-l-factor : 0.6;

    --event-border-style            : solid;
    --event-border-radius           : 3px;
    --event-border-width            : 1px;

    --event-opacity                 : .8;
    --event-hover-opacity           : 1;
}

// Laid out milestone
.b-schedulerbase.b-sch-layout-milestones .b-sch-event-wrap.b-sch-style-border > .b-sch-event.b-milestone > .b-sch-event-content {
    font-size : $event-font-size - 2;
}

.b-sch-style-border.b-sch-custom-color > .b-sch-event {
    &:not(.b-milestone) {
        background-color : currentColor;
        background-image : linear-gradient(rgba(255, 255, 255, .2), rgba(255, 255, 255, .2));
        border-color     : currentColor;

        .b-sch-event-content {
            color : #fff;
        }

        &:hover,
        &.b-sch-event-resizing,
        &.b-sch-event-selected,
        &.b-sch-event-selected:hover {
            background-color : currentColor;
            background-image : none;
            border-color     : currentColor;
        }
    }

    &.b-milestone {
        .b-sch-event-content::before {
            background-color : currentColor;
            background-image : linear-gradient(rgba(255, 255, 255, .2), rgba(255, 255, 255, .2));
            border-color     : currentColor;
        }
    }
}

//endregion

//region Hollow

.b-sch-style-hollow {
    > .b-sch-event,
    > .b-sch-event > .b-sch-event-segments > .b-sch-event { // Segments
        --event-s-factor                : 0;
        --event-l-factor                : #{$event-hollow-l-factor};
        --event-a                       : 0.2;

        --event-hover-s-factor          : 1;
        --event-hover-l-factor          : 1;
        --event-hover-a                 : 1;

        --event-selected-hover-l-factor : 0.8;

        --event-color                   : var(--event-primary-color);
        --event-hover-color             : #{$event-bar-color};

        --event-border-width            : 2px;
        --event-border-radius           : 3px;

        --event-opacity                 : .8;
    }
}

// Layed out milestone
.b-schedulerbase.b-sch-layout-milestones .b-sch-event-wrap.b-sch-style-hollow > .b-sch-event.b-milestone > .b-sch-event-content {
    font-size   : $event-font-size - 2;
    font-weight : $event-font-weight + 100;
}

.b-sch-style-hollow.b-sch-custom-color > .b-sch-event {
    border-color : currentColor;

    // Event
    &:not(.b-milestone) {
        &:hover,
        &.b-sch-event-selected,
        &.b-sch-event-resizing,
        &.b-sch-event-selected:hover {
            background-color : currentColor;

            .b-sch-event-content {
                color : $event-bar-color;
            }
        }
    }

    &.b-milestone {
        .b-sch-event-content::before {
            background-color : rgba(255, 255, 255, 0.5);
        }

        &:hover,
        &.b-sch-event-selected,
        &.b-sch-event-resizing,
        &.b-sch-event-selected:hover {
            .b-sch-event-content::before {
                background-color : currentColor;
            }
        }
    }
}

//endregion

//region Colored

// default colored style if no color specified

.b-sch-style-calendar.b-sch-event-wrap > .b-sch-event, // Variant to use with Calendar
.b-sch-style-calendar.b-sch-event-wrap > .b-sch-event > .b-sch-event-segments > .b-sch-event,
.b-sch-style-colored.b-sch-event-wrap > .b-sch-event,
.b-sch-style-colored.b-sch-event-wrap > .b-sch-event > .b-sch-event-segments > .b-sch-event // Segments
{
    --event-background-l            : 90%;
    --event-border-width            : 3px;
    --event-color                   : hsl(var(--event-primary-color-h), var(--event-primary-color-s), calc(var(--event-primary-color-l) * 0.8));
    --event-border-color            : hsl(var(--event-primary-color-h), var(--event-primary-color-s), calc(var(--event-primary-color-l) * 0.9));
    --event-opacity                 : 0.8;
    --event-selected-hover-l-factor : 0.8;

    &:hover {
        --event-background-l : 85%;
    }

    &.b-sch-event-selected {
        --event-background-l : var(--event-primary-color-l);
        --event-color        : #fff;
    }

    &:not(.b-milestone) {
        border-block-width      : 0;
        border-inline-end-width : 0;
    }

    .b-schedulerbase.b-sch-layout-milestones &.b-milestone {
        --event-border-width : 0px;

        &::before {
            --event-border-width : 3px;
        }
    }

    .b-sch-vertical &:not(.b-milestone) {
        border-top-width    : 3px;
        border-inline-width : 0;
    }
}

.b-sch-style-calendar.b-sch-custom-color > .b-sch-event,
.b-sch-style-colored.b-sch-custom-color > .b-sch-event {
    border-color : currentColor;

    &:not(.b-milestone) {
        background-color : currentColor;
        background-image : linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));

        &:hover,
        &.b-sch-event-resizing,
        &.b-sch-event-selected {
            background-color : currentColor;
            background-image : linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));

            .b-sch-event-content {
                color : #fff;
            }
        }

        &.b-sch-event-selected,
        &.b-sch-event-selected:hover {
            background-color : currentColor;
            background-image : none;
        }
    }

    &.b-milestone {
        .b-sch-event-content::before {
            background-color : currentColor;
            background-image : linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
        }

        &:hover,
        &.b-sch-event-resizing,
        &.b-sch-event-selected {
            .b-sch-event-content::before {
                background-image : linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));
            }
        }

        &.b-sch-event-selected,
        &.b-sch-event-selected:hover {
            .b-sch-event-content::before {
                background-image : none;
            }
        }
    }
}

.b-sch-style-calendar.b-sch-event-wrap > .b-sch-event {
    &,
    & > .b-sch-event-segments > .b-sch-event {
        --event-background-l            : 97%;
        --event-selected-hover-l-factor : 1;

        opacity                         : 1;

        &:hover {
            --event-background-l : 89%;
        }

        &.b-sch-event-selected {
            --event-background-l : 84%;

            .b-sch-event-content * {
                font-weight : $widget-font-weight;
            }
        }

        .b-sch-event-content * {
            font-weight : $widget-font-weight;
            color       : #606060;
        }

        // Should not change color on anything in the event on selection in Calendar
        &.b-sch-event-selected .b-sch-event-content * {
            color : #606060;
        }

        &:not(.b-milestone) .b-sch-event-content {
            margin  : 0;
            padding : 0.3em;
        }
    }
}

//endregion

//region Line

.b-sch-event-wrap.b-sch-style-line {
    > .b-sch-event,
    > .b-sch-event > .b-sch-event-segments > .b-sch-event {
        --event-background-color        : transparent;
        --event-border-color            : hsl(var(--event-primary-color-h), var(--event-primary-color-s), calc(var(--event-primary-color-l) * var(--event-l-factor)));

        --event-color                   : #{$event-milestone-color};
        --event-hover-color             : #{$event-milestone-color};

        --event-border-width            : 5px;

        --event-hover-l-factor          : 0.8;
        --event-selected-hover-l-factor : 0.7;

        justify-content                 : center;
        overflow                        : visible;
        height                          : auto !important;

        .b-sch-event-content {
            padding : 0;
        }

        &.b-active {
            outline-offset : 5px;
        }

        // Event
        &:not(.b-milestone) {
            align-self : center;

            // Line ends
            &::before,
            &::after {
                content          : '';
                border-color     : inherit;
                border-style     : solid;
                border-width     : 0;
                position         : absolute;
                border-radius    : 2px;

                // Remove resize handles
                --handle-width   : unset;
                --handle-height  : unset;
                --handle-inset   : unset;
                --handle-opacity : unset;
                --clip-path      : unset;

                .b-sch-horizontal & {
                    top                       : -9px;
                    height                    : 13px;
                    border-inline-start-width : 5px;
                }

                .b-sch-vertical & {
                    left             : -9px;
                    width            : 13px;
                    border-top-width : 5px;

                    // TODO RTL
                }
            }

            .b-sch-horizontal &::before {
                left : 0;

                // TODO RTL
            }

            .b-sch-vertical &::before {
                border-inline-start-width : 5px;
                top                       : 0;
            }

            .b-sch-horizontal &::after {
                top    : -9px;
                right  : 0;
                height : 13px;

                // TODO RTL
            }

            .b-sch-vertical &::after {
                bottom : 0;
            }
        }

        &.b-milestone {
            top : initial;
        }
    }
}

.b-sch-style-line.b-sch-custom-color > .b-sch-event {
    border-color : currentColor;
    opacity      : .8;

    &:hover,
    &.b-sch-event-selected,
    &.b-sch-event-resizing,
    &.b-sch-event-selected:hover {
        border-color : currentColor;
        opacity      : 1;
    }
}

//endregion

// region Dashed

// default dashed style if no color specified

.b-sch-event-wrap.b-sch-style-dashed > .b-sch-event,
.b-sch-event-wrap.b-sch-style-dashed > .b-sch-event > .b-sch-event-segments > .b-sch-event {
    --event-background-color        : transparent;
    --event-border-color            : hsl(var(--event-primary-color-h), var(--event-primary-color-s), calc(var(--event-primary-color-l) * var(--event-l-factor)));

    --event-color                   : #{$event-milestone-color};
    --event-hover-color             : #{$event-milestone-color};

    --event-border-width            : 5px;
    --event-border-style            : dashed;

    --event-hover-l-factor          : 0.8;
    --event-selected-hover-l-factor : 0.7;

    justify-content                 : center;
    overflow                        : visible;

    .b-sch-horizontal & {
        padding : 5px 0 3px 0;
    }

    .b-sch-event-content {
        padding : 0;
    }

    &:not(.b-milestone) {
        align-self : center;
    }

    &.b-milestone {
        top : initial;
    }
}

.b-sch-style-dashed.b-sch-custom-color > .b-sch-event {
    border-color : currentColor;
    opacity      : .8;

    &:hover,
    &.b-sch-event-selected,
    &.b-sch-event-resizing,
    &.b-sch-event-selected:hover {
        border-color : currentColor;
        opacity      : 1;
    }
}

//endregion

// region Minimal

// default minimal style if no color specified

.b-sch-event-wrap.b-sch-style-minimal > .b-sch-event {
    &,
    & > .b-sch-event-segments > .b-sch-event {

        --event-background-color        : transparent;
        --event-border-color            : hsl(var(--event-primary-color-h), var(--event-primary-color-s), calc(var(--event-primary-color-l) * var(--event-l-factor)));

        --event-color                   : #{$event-milestone-color};
        --event-hover-color             : #{$event-milestone-color};

        --event-border-width            : 1px;

        --event-hover-l-factor          : 0.8;
        --event-selected-hover-l-factor : 0.7;

        overflow                        : visible;
        padding                         : 0;

        &:not(.b-milestone) {
            font-size : 10px;

            .b-sch-horizontal & {
                border-width : 0 0 var(--event-border-width) 0;
                align-items  : flex-end;
            }

            .b-sch-vertical & {
                border-width              : 0;
                border-inline-start-width : var(--event-border-width);
                align-items               : flex-start;
                padding-inline-start      : 0.75em;
            }
        }

        //    .b-sch-vertical & {
        //        left           : 0;
        //        flex-direction : column;
        //    }
        //
        &.b-milestone .b-sch-event-content {
            font-size : 20px;
            left      : 10px;
            position  : relative;

            label {
                font-size : 10px;
                left      : 70%;

                // TODO RTL
            }
        }
    }
}

// Layed out milestone
.b-schedulerbase.b-sch-layout-milestones .b-sch-event-wrap.b-sch-style-minimal > .b-sch-event.b-milestone {
    height    : 20px;
    font-size : 20px;

    .b-sch-event-content {
        font-size : 10px;
        left      : 0;
    }
}

.b-sch-style-minimal.b-sch-custom-color > .b-sch-event {
    border-color : currentColor;
    opacity      : .8;

    .b-sch-event-content {
        color : #777;
    }

    &:hover,
    &.b-sch-event-selected,
    &.b-sch-event-resizing,
    &.b-sch-event-selected:hover {
        border-color : currentColor;
        opacity      : 1;
    }
}

.b-sch-style-line > .b-sch-event,
.b-sch-style-line > .b-sch-event > .b-sch-event-segments > .b-sch-event,
.b-sch-style-dashed > .b-sch-event,
.b-sch-style-dashed > .b-sch-event > .b-sch-event-segments > .b-sch-event {
    .b-sch-horizontal & {
        top          : 5px;
        height       : auto !important;
        border-width : 5px 0 0 0;
    }

    .b-sch-vertical & {
        left           : 0;
        border-width   : 0 0 0 5px;
        flex-direction : column;

        .b-sch-event-content {
            margin : 0;
        }
    }
}

//endregion

// region Rounded

.b-sch-event-wrap.b-sch-style-rounded {
    > .b-sch-event {
        &,
        & > .b-sch-event-segments > .b-sch-event {
            --event-background-l            : 80%;
            --event-a                       : 0.6;
            --event-hover-a                 : 1;
            --event-hover-l-factor          : 0.8;
            --event-selected-hover-l-factor : 0.9;
            --event-color                   : hsl(var(--event-primary-color-h), var(--event-primary-color-s), calc(var(--event-primary-color-l) * 0.6));
            --event-border-color            : var(--event-primary-color);
            --event-border-radius           : 2em;

            &.b-sch-event-selected {
                --event-background-l : var(--event-primary-color-l);
                --event-a            : 1;
                --event-color        : #fff;
            }
        }

        &.b-milestone {
            --event-border-radius : 1em;
        }
    }

    .b-schedulerbase.b-sch-layout-milestones & .b-sch-event.b-milestone {
        --event-border-radius : 3px;
        --event-a             : 1;
        --event-background-l  : 85%;

        > .b-sch-event-content {
            font-size : 11px;
        }
    }
}


.b-sch-event-wrap.b-sch-style-rounded:not(.b-milestone) {
    .b-sch-horizontal & .b-sch-event-content {
        margin : 0 1em;
    }
    .b-sch-vertical & .b-sch-event-content {
        margin : 1em 0;
    }
}

// Custom HEX color
.b-sch-event-wrap.b-sch-style-rounded.b-sch-custom-color > .b-sch-event {
    &:not(.b-milestone) {
        background-color : currentColor;
        background-image : linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));

        &:hover {
            background-color : currentColor;
            background-image : linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
        }

        &.b-sch-event-selected {
            background-image : none;

            .b-sch-event-content {
                color : #fff;
            }
        }
    }

    &.b-milestone {
        .b-sch-event-content {
            background-color : currentColor;
            background-image : linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
        }

        &:hover .b-sch-event-content {
            background-image : linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
        }

        &.b-sch-event-selected .b-sch-event-content {
            background-image : none;
        }
    }
}

// endregion
