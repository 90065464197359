.b-displayfield {
    color : inherit;

    .b-field-inner {
        border     : 0;
        background : transparent;

        span {
            padding : $field-input-padding;
        }
    }
}
