.b-toolbar.b-undoredo {
    padding : 0;
    gap     : 0;

    > * {
        border-radius : 0;
    }

    .b-buttongroup & {
        // When in a ButtonGroup, adjoining button borders are overlayed.
        // Overlay our undo button over the preceding button's border
        &:not(:first-child) {
            > button:first-child {
                margin-inline-start : $buttongroup-button-left-margin;
            }
        }

        > .b-widget {
            margin-inline-end : 0;
        }
    }

    .b-widget.b-combo {
        flex   : 1 1 250px;
        margin : 0 0.5em;

        .b-theme-material & {
            .b-field-inner {
                padding-inline : 0.7em;
            }
        }
    }
}
