.b-sch-vertical .b-sch-event-wrap.b-milestone-wrap {
    transform           : translateY(-50%);
    margin-inline-start : 0;
    height              : 1em;

    .b-sch-event {
        padding-inline-start : 0;
        width                : 100%;

        .b-sch-event-content {
            justify-content : center;
            margin          : 0;

            label {
                left : unset;
                top  : 105%;
            }
        }
    }
}
