@import '../../variables';

.b-tooltip {
    --panel-background-color : #{$tooltip-background-color};

    // Only disallow selection if we are forbidding mouseovering
    &:not(.b-allow-over) {
        @include user-select-none();
    }

    .b-tooltip-loading .b-icon {
        display           : inline-block;
        margin-inline-end : .5em;
    }

    // Show slimmer tooltip for pure text, no-html content
    .b-panel-content.b-no-child-elements {
        padding-block : 0.25em;
        margin-block  : .5em;
    }
}

.b-tooltip-content {
    background  : inherit;
    color       : $tooltip-color;
    font-size   : 1em;
    line-height : 1.4em;
    padding     : 1em;

    a {
        color : $tooltip-color;
    }
}

// Allow themes that use larger header font size to adjust it for headers docked to the sides
.b-tooltip-header.b-dock-right,
.b-tooltip-header.b-dock-left {
    .b-header-title {
        font-size : $tooltip-side-header-font-size;
    }
}

// Override color for tools in tooltip header
.b-tooltip .b-tooltip-header .b-tool {
    color: $tooltip-header-tool-color;
}
