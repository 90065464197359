@import "../variables";

.b-column-lines-canvas {
    z-index : $timeaxis-ticklines-zindex;
}

.b-columnlines .b-grid-cell:not(:last-child) {
    border-inline-end : 1px solid $grid-cell-border-color;
}

.b-columnlines .b-group-row .b-grid-cell {
    border-inline-end-color : transparent;
}

.b-column-line,
.b-column-line-major {
    height            : 100%;
    position          : absolute;
    border-left-width : 1px; // RTL OK
    border-left-style : solid;
}

.b-column-line {
    border-color : $columnlines-tick-color;
}

.b-column-line-major {
    border-color : $columnlines-major-color;
    z-index      : 1;
}

.b-sch-vertical {
    .b-column-line:not(.b-resource-column-line),
    .b-column-line-major {
        height           : auto;
        width            : 100%;
        position         : absolute;
        border-left      : none;
        border-top-width : 1px;
        border-top-style : solid;
    }

    .b-resource-group-divider {
        border-color : $grid-header-border-color;
    }
}

.b-animating .b-resource-column-line {
    transition : left .2s linear;
}
