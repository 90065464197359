.b-notestab .b-taskeditor-notes-field {
    height : 100%;

    .b-field-inner {
        align-self: stretch;

        textarea {
            resize : none;
        }

    }
}
