@import "../../variables";

.b-quick-hit-cell {
    background : $quickfind-background-color;
}

.b-quick-hit-text {
    font-weight : $quickfind-font-weight;
    background  : $quickfind-background-color;
}

.b-quick-hit-text {
    padding : .3em 0;
}

.b-quick-hit-header {
    position : absolute;
    top      : .3em;
    left     : .3em;
    bottom   : .3em;
    right    : .3em;
    z-index  : 1;

    &.b-quick-hit-mode-grid {
        bottom  : auto;
        height  : 3em;
        z-index : 100;
        opacity : 0.75;
    }
}

.b-quick-hit-field {
    display         : flex;
    justify-content : center;
    align-items     : center;
    position        : absolute;
    top             : 0;
    left            : 0;
    bottom          : 0;
    right           : 0;
    padding         : .5em 0;
    background      : $quickfind-background-color;
}

.b-quick-hit-cell-badge,
.b-quick-hit-header .b-quick-hit-badge {
    position    : absolute;
    top         : .4em;
    right       : .4em;
    font-size   : .7em;
    line-height : .7em;

    .b-rtl & {
        right : auto;
        left  : .4em;
    }
}

.b-quick-hit-header .b-quick-hit-badge {
    color : $quickfind-header-badge-color;
}

.b-quick-hit-cell-badge {
    color : $quickfind-badge-color;
}


