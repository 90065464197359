@import '../variables';

.b-dragselect-rect {
    position         : absolute;
    left             : 0; // RTL OK
    top              : 0;
    transform-origin : 0 0;
    pointer-events   : none;
    z-index          : 100;

    border           : $eventdragselect-border;
}
