@import '../../variables';

.b-fieldfilterpicker {

    .b-fieldfilterpicker-inputs {
        flex-wrap: wrap;
        gap: 0.25em 0.5em;
    }

    .b-fieldfilterpicker-property,
    .b-fieldfilterpicker-operator,
    .b-fieldfilterpicker-values {
        flex: 1 0 8em;
    }

    .b-fieldfilterpicker-values-number {
        flex: 1 0 3em;
    }

    .b-fieldfilterpicker-values-duration {
        flex: 1 0 6em;
    }

    .b-fieldfilterpicker-values {
        overflow: hidden;
    }

    .b-fieldfilterpicker-values.b-hidden {
        display: none;
    }

    .b-fieldfilterpicker-values-multiple {
        flex: 0 0 100%;
    }

    .b-fieldfilterpicker-combo-locked > .b-field-inner {
        border: none;
        background: none;

        input {
            padding-inline-start: 0;
        }
    }

    .b-fieldfilterpicker-values {
        display: flex;
    }

    .b-fieldfilterpicker-values-multiple {
        width: 100%;
    }

    .b-fieldfilterpicker-values > .b-field {
        flex: 1;
        margin-block-end: 0;
        align-self: baseline;
    }

    .b-fieldfilterpicker-value-separator {
        flex: none;
        padding: 0 0.4em;
        align-self: center;
    }

    .b-fieldfilterpicker-values > .b-field:last-child::after {
        display: none;
    }
}

.b-fieldfilterpickergroup {

    .b-checkbox[data-ref="enableAllCheckbox"] .b-checkbox-label::before {
        margin-inline-end: 0.5em;
    }

    .b-fieldfilterpickergroup-row {
        padding-top: $fieldfilterpickergroup-gap;
        position: relative;
        flex-shrink: 0;
        align-items: baseline;

        &:first-child {
            padding-top: 0;
            
            &::before {
                display: none;
            }
        }

        &::before {
            content: attr(data-separator-text);
            font-size: 85%;
            position: absolute;
            text-align: center;
            line-height: $fieldfilterpickergroup-gap;
            width: 100%;
            height: $fieldfilterpickergroup-gap;
            top: 0;
            background-image: linear-gradient(
              to right, 
              #e0e0e7, 
              #e0e0e7 44%, 
              transparent 46%, 
              transparent 54%, 
              #e0e0e7 56%);
            background-position: 0 50%;
            background-size: 100% 1px;
            background-repeat: no-repeat;
        }

        &:not(.b-fieldfilterpickergroup-row-removable) {
            padding-inline-end: 2.25em;
        }
    }

    .b-fieldfilterpickergroup-filter-active {
        flex: 0 0 2rem;

        // When rendered as a slide toggle, we need a little more
        &.b-slidetoggle {
            flex-basis : 2.5rem;
        }
        align-self: baseline;

        position: relative;
        top: 1px;
    }

    .b-fieldfilterpickergroup-other-filters {
        padding: 0.5em 0;
    }

    .b-fieldfilterpickergroup-add-button {
        align-self: center;
        margin-top: 1.1em;
    }

    .b-fieldfilterpickergroup-remove {
        inline-size: 2em;
        margin-inline-start: 0.25em;
    }
}
