@import 'vars/stockholm-vars';
@import '../all';

.b-sch-style-plain {
    border-radius : 2px;
}

.b-gridbase.b-no-column-lines .b-sch-header-timeaxis-cell {
    border-right-color : transparent;
}

.b-sch-timeaxiscolumn {
    text-transform : unset;
}

.b-recurrenceeditor .b-recurrenceeditorpanel-content.b-panel-content .b-button-group-label {
    justify-self : end;
}
