@import '../../variables';

.b-calendareditorexceptiontab {

    .b-calendareditor-form {

        .b-field.b-slidetoggle.b-slidetoggle-splitter {
            display               : grid;
            grid-template-columns : 1fr auto auto 1fr;
            margin-block-start    : 0;

            &::before, &::after {
                content    : '';
                height     : 1px;
                background : $field-border-color;
            }
            label {
                margin-inline-start : 1em;
            }
            .b-field-inner {
                margin-inline-end : .6em; // The toggle element has its own end margin
            }
        }

        .b-calendareditoravailabilityrangecontainer {
            padding-inline-start: 4.5em;

            .b-availability-range {
                .b-timefield {
                    width : 11em;
                }
            }
        }

        .b-recurrenceeditor .b-recurrencemonthdaysbuttongroup {
            margin-block-start : 0;
        }
    }

    .b-divider {
        margin-block : 1.5em 1em;
    }
}
