@import '../../variables';

.b-grid-header.b-group {
    // display icon on grouped header
    .b-grid-header-text .b-sort-icon::after {
        @include b-grid-header-icon($grid-group-column-header-icon-color);
        content   : "#{ $fa-var-signal }";
        transform : rotate(90deg);
    }

    // rotate it when grouping descending, uses transition
    &.b-desc .b-grid-header-text .b-sort-icon::after {
        transform : rotate(270deg) scaleX(-1);
    }
}

.b-group-row {
    background : $grid-group-header-background-color;

    // no border-right in group headers
    .b-grid-cell {
        border-inline-end-color : transparent;
    }

    .b-group-title {
        // TODO: Remove usage of left and right for alignment.
        // Use start and end to be LTR/RTL neutral.
        &.b-grid-cell-align-right, &.b-grid-cell-align-end {
            justify-content : flex-end;
        }

        &.b-grid-cell-align-center {
            justify-content : flex-start;
        }
    }
}

.b-grid-cell.b-group-title {
    color          : $grid-group-header-color;
    overflow       : visible;
    contain        : size layout style;
    font-weight    : $grid-group-header-font-weight;
    text-transform : $grid-group-header-text-transform;

    .b-firefox & {
        // `contain : style;` is not supported by Firefox
        contain : size layout;
    }
}


.b-group-state-icon {
    @extend .b-content-icon;
    margin-inline-end : .5em;
    cursor            : pointer;
    color             : $grid-group-header-icon-color;
}

.b-stripe .b-grid-row.b-group-row,
.b-grid-row.b-group-row {
    border-bottom : $grid-group-header-line-width solid $grid-group-header-line-color;
    cursor        : pointer;
}

.b-gridbase.b-group .b-grid-header .b-sort-icon {
    display: contents;
}
