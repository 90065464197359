@import '../../variables';

.b-carousel-content {
    &:not(.b-carousel-empty) {
        padding : 0;
    }

    > .b-carousel-content {
        overflow : hidden;
    }

    &.b-carousel-empty > .b-carousel-inner-ct,
    &:not(.b-carousel-empty) > .b-carousel-empty-text {
        display : none;
    }
}
