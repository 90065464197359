.b-calendareditoravailabilityrangecontainer {

    .b-availability-range {
        flex        : 1 0 100%;
        align-items : center;

        .b-timefield {
            width : 6em;
        }

        [data-ref="removeRangeButton"] {
            display : inherit;
        }

        [data-ref="addRangeButton"] {
            margin-inline-start : auto;
            display : inherit;
        }

        &:only-child {

            [data-ref="removeRangeButton"] {
                display : none;
            }

        }

        &:not(:first-child) {

            [data-ref="addRangeButton"] {
                display : none;
            }

        }
    }
}
