@import '../../variables';

.b-splitter {
    // When hovered or being moved, the inner pops out to cover a larger area.
    // How much it does that by depends on whether the pointer capability is coarse or fine.
    @media (pointer:coarse) {
        --splitter-inner-size       : #{$touch-splitter-size};
    }
    @media (pointer:fine) {
        --splitter-inner-size       : 0;
    }

    background-color : $splitter-background-color;
    flex             : 0 0 $splitter-size;
    position         : relative;
    touch-action     : none;

    &.b-disabled {
        flex           : 0 0 $splitter-size-disabled;
        pointer-events : none;
    }

    // Allow any touch handle to overflow
    &:not(.b-disabled) {
        overflow : visible;
    }

    // Define the hover/move inner
    &::after {
        content          : '';
        position         : absolute;
        left             : 0;
        top              : 0;
        z-index          : 1;
    }

    &:hover,
    &.b-hover,
    &.b-moving {
        &::after {
            background-color : $splitter-background-color;
        }
    }

    &.b-horizontal {
        cursor     : ns-resize;
        min-height : $splitter-size;

        &.b-disabled {
            min-height : $splitter-size-disabled;
        }

        // Default position and size of inner.
        // These vars are defined based upon whether pointer is coarse
        &::after {
            height    : var(--splitter-inner-size);
            width     : 100%;
        }

        // When hovered or being moved, the inner pops out to cover a larger area.
        &:hover,
        &.b-hover,
        &.b-moving {
            &::after {
                top    : calc((#{$splitter-hover-size} - #{$splitter-size}) / -2);
                height : #{$splitter-hover-size};
            }
        }
    }

    &.b-vertical {
        cursor    : ew-resize;
        min-width : $splitter-size;

        &.b-disabled {
            min-width : $splitter-size-disabled;
        }

        // Default position and size of inner
        &::after {
            width      : var(--splitter-inner-size);
            height     : 100%;
        }

        // When hovered or being moved, the inner pops out to cover a larger area.
        &:hover,
        &.b-hover,
        &.b-moving {
            &::after {
                left  : calc((#{$splitter-hover-size} - #{$splitter-size}) / -2);
                width : #{$splitter-hover-size};
            }
        }
    }

    // Hide splitter if not having two sibling
    &:last-child,
    &:first-child {
        visibility : hidden;
        flex       : 0;
        min-width  : 0;
    }
}
