@import '../variables';

.b-timelinehistogram {
    .b-grid-row {
        z-index : $timeaxis-ticklines-zindex + 1;
    }

    .b-timelinehistogram-cell {
        padding            : 0;
        border-inline-end-width : 0;
    }

    // No focus outline for histogram cells
    .b-timeline-subgrid .b-grid-cell.b-focused::after {
        display : none;
    }
}

.b-timelinehistogram-histogram {
    padding          : 0;
    background-color : transparent;
}
