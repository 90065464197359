@import '../variables';
@import '../functions';

.b-noselect {
    @include user-select-none();
}

// Default state of RTL negation for use in calc() expressions is 1
:root, :host {
    --rtl-negate : 1;
}

// Within RTL elements, this allows calc expressions to be negated.
.b-rtl {
    --rtl-negate : -1;
}

.b-released,
.b-hide-display,
.b-theme-info {
    display : none !important;
}

.b-hide-visibility {
    visibility : hidden !important;
}

.b-hide-offscreen {
    visibility : hidden !important;
    position   : absolute !important;
    top        : -10000em;
    left       : -10000em;
}

// An element, which, when the owner has the class b-show-yscroll-padding added to it
// emulates a y scroller without showing scrolling furniture.
// By default these are hidden and only shown inside widgets which show a visible scrollbar.
.b-yscroll-pad {
    display : none;
}

.b-visible-scrollbar {
    // Only show a yscroll pad element when on a scrollbar showing platform
    // and the b-show-yscroll-padding class is added to the containing element.
    .b-show-yscroll-padding > .b-yscroll-pad {
        display             : block;

        // In case DomSync moves it around, it always lays out at flex-end
        order               : 9999;

        // Look like a scrollbar
        border-inline-start : 1px solid $scrollbar-border-color;

        // This gives it the correct size.
        // visibility:hidden is needed for IE which always displays
        // scroller furniture even if there is no overflow.
        // Left margin makes it 1 narrower so that outer can display its left margin.
        .b-yscroll-pad-sizer {
            overflow-x          : hidden;
            overflow-y          : scroll;
            visibility          : hidden;
            margin-inline-start : -1px;
            height              : 0;
        }
    }
}

// Used by DomHelper.highlight
.b-fx-highlight {
    z-index            : 9999 !important;
    animation-name     : b-fx-highlight-animation;
    animation-duration : 1s;
    animation          : b-fx-highlight-animation 1s ease 0s 1;
}

@keyframes b-fx-highlight-animation {
    0% {
        box-shadow : none;
    }
    50% {
        box-shadow : 0 0 10px 5px $row-highlight-box-shadow-color;
    }
    100% {
        box-shadow : none;
    }
}

@keyframes b-slide-in-from-right {
    30% {
        transform : translateX(-30%);
        opacity   : 0.1;
    }
    30.01% {
        transform : translateX(30%);
    }
    100% {
        transform : translateX(0);
        opacity   : 1
    }
}

@keyframes b-slide-in-from-left {
    30% {
        transform : translateX(30%);
        opacity   : 0.1;
    }
    30.01% {
        transform : translateX(-30%);
    }
    100% {
        transform : translateX(0);
        opacity   : 1;
    }
}

@keyframes b-slide-in-from-below {
    30% {
        transform : translateY(-30%);
        opacity   : 0.1;
    }
    30.01% {
        transform : translateY(30%);
    }
    100% {
        transform : translateY(0);
        opacity   : 1;
    }
}

@keyframes b-slide-in-from-above {
    30% {
        transform : translateY(30%);
        opacity   : 0.1;
    }
    30.01% {
        transform : translateY(-30%);
    }
    100% {
        transform : translateY(0);
        opacity   : 1;
    }
}

// Vertical slide in is always next come from below and previous from above
.b-slide-vertical {
    &.b-slide-in-next {
        animation : b-slide-in-from-below 0.3s ease 0s 1 normal;
    }

    &.b-slide-in-previous {
        animation : b-slide-in-from-above 0.3s ease 0s 1 normal;
    }
}

// Horizontal side in respects RTL.
// In RTL, next comes from the left and previous from the right.
:not(.b-slide-vertical) {
    &.b-slide-in-next {
        animation : b-slide-in-from-right 0.3s ease 0s 1 normal;
    }

    &.b-slide-in-previous {
        animation : b-slide-in-from-left 0.3s ease 0s 1 normal;
    }
}

.b-rtl {
    :not(.b-slide-vertical) {
        &.b-slide-in-next {
            animation : b-slide-in-from-left 0.3s ease 0s 1 normal;
        }

        &.b-slide-in-previous {
            animation : b-slide-in-from-right 0.3s ease 0s 1 normal;
        }
    }
}

// Class added to parent element of elements being slid around by the above classes.
// While the animation is in flight, no overflow must appear in the parent element.
.b-sliding-child-element {
    // When clip is not supported.
    overflow : hidden !important;

    overflow : clip !important;
}

/**
 * DomHelper size monitoring polyfill for when ResizeObserver not available
 * now only necessary on IE.
 */
.b-no-resizeobserver.b-resize-monitored {
    position : relative;

    .b-resize-monitors {
        position   : absolute;
        left       : 0;
        top        : 0;
        width      : 100%;
        height     : 100%;
        visibility : hidden;
        overflow   : hidden;

        > * {
            width    : 100%;
            height   : 100%;
            overflow : hidden;
        }

        > .b-resize-monitor-expand:after {
            content : '';
            display : block;
            width   : 100000px;
            height  : 100000px;
        }

        > .b-resize-monitor-shrink:after {
            content    : '';
            display    : block;
            width      : 200%;
            height     : 200%;
            min-width  : 1px;
            min-height : 1px;
        }
    }
}

// This is the element which lifts floating Widgets above the document.
// Within this, they rely on DOM order for visual stacking.
// A "to top" operation makes the Widget's element the last child in
// its owning .b-float-root.
.b-float-root {
    position        : fixed;
    inset           : 0;
    pointer-events  : none;

    // Safari occasionally selects the float root when doing row reorder on iPhone
    &.b-safari {
        @include user-select-none();
    }

    overflow        : clip;
    z-index         : $float-root-z-index;
    contain         : strict;
    display         : flex;
    justify-content : center;
    align-items     : center;

    > .b-floating {
        position           : absolute;
        contain            : layout style;

        .b-firefox & {
            // `contain : style;` is not supported by Firefox
            contain : layout;
        }

        pointer-events     : all;
        transition         : opacity 0.2s;
        box-shadow         : $float-box-shadow;
        top                : 0; // gets strange pos in safari otherwise
        inset-inline-start : 0; // gets strange pos in safari otherwise

        // In Safari active buttons in button groups overlaps floating pickers unless this is set,
        // probably different z-index stacking rules
        z-index            : $float-root-z-index + 1;
        // Opacity of floating Widgets is animated to 1 upon show.
        &.b-hidden {
            opacity : 0;
        }

        // For Field dropdowns, it's visually desirable that the
        // shadow must not shade the field it's aligned to, so flip
        // the shadow orientation when aligned above.
        // Only when flush, not when there's an anchor separating the widget boundaries.
        &.b-aligned-above:not(.b-anchored) {
            box-shadow : $aligned-above-float-box-shadow;
        }
    }
}
