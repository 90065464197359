@import '../../variables';

@keyframes maskOpacity {
    0% {
        opacity : 0;
    }
    100% {
        opacity : 1;
    }
}

.b-masked {
    // required to make sure masked Element can contain the Mask (which is absolute positioned)
    position : relative;
}

.b-mask {
    position         : absolute;
    top              : 0;
    left             : 0;
    bottom           : 0;
    right            : 0;
    z-index          : $mask-z-index;
    background-color : transparent;

    &:not(.b-visible) {
        opacity        : 0;
        pointer-events : none;
    }

    &:not(.b-prevent-transitions) {
        transition : opacity 1s;

        &:not(.b-delayed-show) {
            animation : maskOpacity .3s ease 0s 1;
        }

        &.b-hidden {
            animation : maskOpacity .2s ease 0s 1 reverse;
        }
    }

    // This must follow the other rules that set opacity since they all (at present)
    // have the same specificity. It was tempting to extend the show animation to have
    // a delay to achieve the show delay, but this wouldn't quite do for the following
    // reasons:
    //
    //   1. Animations can be disabled but showDelay works independently.
    //   2. If an error occurs, the showDelay is cancelled and the error is shown.
    &.b-delayed-show {
        opacity : 0;
    }

    &.b-mask-bright,
    &.b-mask-bright-blur {
        background-color : #FFFFFFB2;
    }

    &.b-mask-dark,
    &.b-mask-dark-blur {
        background-color : #0000004C;
    }
}

.b-mask-content {
    position         : absolute;
    top              : 50%;
    left             : 50%;
    transform        : translate(-50%, -50%);

    display          : flex;
    flex-direction   : column;
    color            : $mask-color;
    background-color : $mask-background-color;
    border-radius    : $widget-border-radius;
}

.b-mask-text {
    padding     : 1em;
    display     : flex;
    align-items : center;
}

.b-mask-progress-bar {
    height           : 3px;
    background-color : $mask-progress-color;
}

.b-mask-icon {
    margin-inline-end : .5em;
}

.b-masked-dark-blur,
.b-masked-bright-blur {
    filter     : blur(3px);
    transform  : scale(1.01);
    transition : all .5s;
}
