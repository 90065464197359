@import '../../variables';

.b-panel .b-bryntumcodeeditor-body-wrap {
    background : $codeeditor-background-color;

    .monaco-editor .scroll-decoration {
        box-shadow : none;
    }
}

.b-codeeditor-content {
    // Padding done by Monaco editor padding option
    padding : 0;

    .line-numbers:not(.active-line-number) {
        opacity : .3;
    }

    .folded-background,
    .monaco-editor .view-overlays .current-line {
        background : transparent !important;
        border     : 0 !important;
    }

    .monaco-editor .lines-content .core-guide-indent {
        opacity : .4;
    }
}

.b-widget.b-codeeditor {
    width : 450px;

    // When on a large screen
    @media (min-width : 2000px) {
        width : 700px;
    }

    flex  : unset;

    .b-header-title {
        font-size   : 18px;
        font-weight : normal;
    }

    .demo-header {

        .b-button {
            &.b-text {
                min-height : 2.5em;
            }
        }

        .title-container {
            align-items : center;
        }

        .title {
            background           : none;
            padding-inline-start : 0;
            font-size            : 18px
        }

        .b-tool {
            color     : #fff;
            height    : 2.5em;
            font-size : 1.1em;
        }
    }

    [data-ref="tbar"] {
        .b-toolbar-content {
            justify-content : flex-end;
        }
    }

    &:not(.b-collapsed) {
        border-inline-start : 5px solid #d8d9da;

        body.b-theme-classic-dark & {
            border-inline-start-color : #111;
        }
    }


    // When collapsed, do not show the placeholder.
    &.b-collapsed, &.b-collapsing {
        .b-panel-collapse-revealer {
            display : none;
        }
    }

    &:not(.b-resizing) {
        transition : border-width .1s;
    }

    .b-toolbar {
        flex   : 0 0 auto;
        height : 4em;
    }

    .b-codeeditor-body-wrap, .b-panel-overlay {
        overflow : hidden;
    }

    .b-panel-content {
        direction : ltr;
    }

    &.b-resizing * {
        user-select : none;
    }

    &.b-over-resize-handle {
        cursor : ew-resize;
    }

    .b-header-title {
        i {
            margin-inline-end : .5em;
        }
    }

    &.b-hidden {
        display                   : flex !important;
        border-inline-start-width : 0;
    }

    .b-codeeditor-header {
        background-color : #0076f8;
    }

    .b-bottom-toolbar {
        background-color : #f3f4f5;
        color            : #4f5964;
        transition       : color .2s, background-color .2s;
        height           : 3em;

        .readonly & {
            background-color : #ff8d46;
        }

        [data-ref="cursorPos"] {
            margin-inline-start : auto;
        }
    }

    &.invalid {
        .b-codeeditor-header i {
            color : #900101ad;
        }

        .b-bottom-toolbar {
            color      : #fff;
            background : #b71c1c;
        }
    }

    .b-bottom-toolbar {
        .b-toolbar-content {
            padding : 0.5em 1em !important;
        }
    }

    .b-panel-header {
        &.b-dock-top {
            border-bottom : none;
        }

        .b-tool {
            height : 2.5em;
            width  : 2.5em;
        }
    }
}

.b-combo-picker {
    .b-editor-file-type {
        margin-inline-end : 0.5em;
    }

    .b-editor-folder {
        color: #a0a0a0;
    }
}

@media (max-width : 450px) {
    .b-codeeditor .b-icon-download {
        display : none !important;
    }
}
