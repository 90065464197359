@import '../variables';

.b-popup.b-taskeditor {
    @extend .b-taskeditor;

    .b-bottom-toolbar {
        > button {
            flex         : none;
            width        : 10em;
            margin-inline-end : .5em;
            min-height   : 2.5em;

            &:last-child {
                margin-inline-end : 0;
            }
        }
    }

    .b-chip {
        font-size : .9em;
    }
}

.b-schedulerpro-taskeditor .b-watermark {
    display : none;
}
