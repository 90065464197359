@import '../variables';

.b-resourcecollapse-cell {
    cursor : pointer;

    .b-icon {
        transition : transform 0.2s !important;

        &.b-flip {
            transform : rotate(180deg);
        }
    }
}
