@import '../variables';

.b-resourceassignment-cell {
    padding-block : 0.4em;
}

.b-resource-avatar-container {
    display     : flex;
    align-items : center;
    height      : 100%;

    .b-resource-avatar {
        margin-inline-end : 2px;
        height            : 100%;
        width             : auto;

        &.b-drag-original {
            opacity : .5;
        }
    }

    &:empty {
        height : 2.2em;
    }
}

.b-draggable-resource-avatars .b-resource-avatar-container > .b-resource-avatar {
    cursor : pointer;
}

.b-resource-initials {
    // Only apply background if there is none specified by an eventColor
    &:not([class*="b-sch-"]) {
        background : $gantt-parent-background-color;
    }
}

.b-overflow-img {
    overflow : hidden;
    position : relative;

    .b-resource-avatar {
        filter            : brightness(60%);
        margin-inline-end : 0;
    }

    .b-overflow-count {
        position       : absolute;
        left           : 50%;
        top            : 50%;
        transform      : translate(-50%, -50%);
        color          : #fff;
        pointer-events : none;
    }
}
