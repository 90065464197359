@import '../../variables';

.b-list {
    display          : block;
    background-color : $list-background-color;
    outline          : none;
    padding          : 0;
    margin           : 0;
    @include user-select-none();

    &.b-floating {
        border-radius : $widget-border-radius;
    }

    &.b-empty:not(.b-masked) {
        min-height : initial !important; // empty rule must override inline style

        // If there is empty text defined on this empty list, display it
        &[data-empty-text] {
            &::after {
                content : attr(data-empty-text);
                display : block;
                padding : .8em;
                color   : $list-color;
            }
        }
    }

    &.b-masked {
        padding : 2em 0;
    }

    // Selected icon is not layed out by default/
    .b-selected-icon {
        margin-inline-end : 0.5em;
        display           : none;
    }

    .b-select-all-item {
        border-bottom    : 1px solid;
        font-weight      : 500;
        position         : sticky;
        top              : 0;
        background-color : $list-background-color;
        z-index          : 1;

        :has(.b-list-title) > & {
            top : 2em;
        }
    }

    .b-list-title {
        position         : sticky;
        top              : 0;
        font-weight      : 500;
        background-color : $list-background-color;
        z-index          : 1;
        height           : 2em
    }

    &.b-multiselect {
        .b-list-item {
            // Inside a multiselect list, the selected icon is layed out, but invisible
            .b-selected-icon {
                display    : initial;
                visibility : hidden
            }

            // Selected icon becomes visible when selected
            &.b-selected {
                .b-selected-icon {
                    visibility : visible;
                }
            }
        }
    }
}

.b-list-item {
    position             : relative;
    padding              : .8em;
    color                : $list-color;
    cursor               : pointer;
    background-color     : transparent;
    transition           : background-color 0.2s, color 0.2s;
    border-top           : 1px dotted transparentize($list-item-border-color, .2);
    min-width            : 100px;
    overflow             : hidden;
    overflow             : clip;
    white-space          : nowrap;
    display              : flex;
    flex-direction       : row;
    align-items          : center;

    // Higher specificity rule to override later rules with base specificity
    &li {
        // Tree nodes include their nesting level as a CSS prop so they can be indented
        padding-inline-start : calc(var(--tree-level, 0) * 1em + .8em);
    }

    &.b-hidden {
        display : none;
    }

    &:first-child {
        border-top              : none;
        border-top-right-radius : $widget-border-radius;
        border-top-left-radius  : $widget-border-radius;
    }

    &:last-child {
        border-bottom-right-radius : $widget-border-radius;
        border-bottom-left-radius  : $widget-border-radius;
    }

    &.b-active,
    &:focus {
        outline : none;

        // Mouse hover rendition.
        &:not(.b-disabled) {
            background-color : $list-highlight-color;
            color            : $list-active-color;
        }
    }

    &:is(.b-list-item-group-header, .b-list-item-tree-parent) {
        font-weight : 500;

        .b-icon-check {
            display : none;
        }

        .b-list-expander-icon {
            margin-inline-start : auto;
            margin-inline-end   : 0;
            font-size           : 1.1em;
            min-width           : 0;
            min-height          : 0;
            padding             : 0;
            height              : 1.1em;
            width               : 1.1em;
            opacity             : .8;
            color               : var(--button-text-color);

            // Easier to tap on touch devices where missing the icon
            // would unexpectedly toggle the selection.
            @media (pointer : coarse) {
                font-size : 1.4em;
                width     : 1.4em
            }

            &:hover {
                opacity : 1;
            }
        }
    }

    .b-grouped &:not(.b-list-item-group-header, .b-list-item-tree-parent) {
        padding-inline-start : 2em !important; // Need to override margin setting
    }
}

.b-virtualized .b-list-item {
    height     : 1.5em; // To match line-height used
    // To not have to take padding into account
    box-sizing : content-box;
}
