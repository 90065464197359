@import '../../variables';

.b-grid.b-readonly.b-actioncolumn-readonly .b-action-cell {
    filter         : grayscale(1);
    opacity        : 0.2;
    pointer-events : none;
}

.b-action-cell {
    text-align : end;
}

.b-action-ct {
    display   : flex;
    flex-flow : row nowrap;
}

.b-action-item {
    margin     : 0 -0.2em;  // These are 2emx2em Tools. Space them closer.
    text-align : center;
    font-size  : 1.2em;
    color      : $grid-cell-action-column-color;
}

.b-action-cell.b-grid-cell .b-action-item {
    transition : all 0.15s ease-in-out; // override stockholm '*' rule
}

.b-action-item:hover {
    // used scale3d to fix shifting/jumping bug on scaling animation
    transform : scale3d(1.10, 1.10, 1);
    color     : $action-item-hover-color;
}
