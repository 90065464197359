@import '../../variables';

html.b-export-root,
.b-export-root body {
    margin : 0;
}

html.b-print-root,
.b-print-root body {
    margin                     : 0;
    height                     : auto;
    overflow                   : unset;
    display                    : block;
    /* Force printing background, without users having to check "Background graphics" in print settings */
    -webkit-print-color-adjust : exact !important;
    print-color-adjust         : exact !important;
}

html.b-export-root {
    overflow : auto;
}

.b-export-root body,
.b-print-root body {
    position : relative;
}

.b-print-root {
    .b-float-root,
    .b-scrollbar-measure-element,
    .b-grid-header-resize-handle {
        display : none !important;
    }
}

.b-print:not(.b-safari) .b-page-wrap {
    page-break-after : always;
}

.b-export {
    &:not(.b-print) {
        overflow  : hidden;
        display   : flex;
        flex-flow : column nowrap;
    }

    .b-gridbase {
        min-height: 0 !important;
    }

    .b-export-content {
        display        : flex;
        flex-direction : column;
    }

    &:not(.b-print) .b-export-content {
        position : absolute;
        height   : 100%;
    }

    .b-export-body {
        flex : 1;
    }

    .b-grid-header-container {
        margin-inline-end : 0 !important;
        border-inline-end : none !important;
    }

    .b-grid-body-container.b-widget-scroller {
        overflow-y : hidden !important;
    }

    .b-grid-footer-container {
        padding-inline-end : 0 !important;
    }

    .b-virtual-scrollers {
        display : none;
    }

    // Export HTML does not include scrollbars, so always hide the pad element
    &.b-visible-scrollbar {
        .b-show-yscroll-padding > .b-yscroll-pad {
            display : none;
        }
    }

    &.b-multipage {
        .b-export-content {
            width  : 100%;
            height : 100%;
        }

        .b-export-body {
            overflow : hidden;
        }
    }
}

.b-export-header,
.b-export-header * {
    box-sizing : border-box;
}

@media print {
    .b-page-wrap {
        overflow : hidden;
    }

    .b-grid-body-container {
        contain : paint !important;
    }
}
