@import '../grid-sass/variables';

$event-color-red                            : #ff8787 !default;
$event-color-pink                           : #f783ac !default;
$event-color-purple                         : #ea80dc !default;
$event-color-magenta                        : #ff4dff !default;
$event-color-violet                         : #9775fa !default;
$event-color-indigo                         : #748ffc !default;
$event-color-blue                           : #4dadf7 !default;
$event-color-cyan                           : #3bc9db !default;
$event-color-teal                           : #38d9a9 !default;
$event-color-green                          : #69db7c !default;
$event-color-gantt-green                    : #a5d8a7 !default;
$event-color-lime                           : #a9e34b !default;
$event-color-yellow                         : #fdd835 !default;
$event-color-orange                         : #ffa94d !default;
$event-color-deep-orange                    : #ff7043 !default;
$event-color-gray                           : #a0a0a0 !default;
$event-color-light-gray                     : #e0e0e7 !default;
$event-color-black                          : #000 !default;

$event-bar-background-color                 : $event-color-green !default;
$event-bar-color                            : #fff !default;

$event-milestone-color                      : #777 !default;
$event-font-size                            : 14px !default;
$event-font-weight                          : 400 !default;
$event-selected-font-weight                 : 500 !default;

$parent-event-bar-background-color          : #8bc34a;

$event-bar-selected-background-color        : rgba(0, 0, 0, 0.25) !default;
$event-bar-selected-color                   : #fff !default;

$event-bar-hover-background-color           : rgba(0, 0, 0, 0.15) !default;
$event-bar-hover-color                      : $event-bar-color !default;

$event-bar-selected-hover-background-color  : rgba(0, 0, 0, 0.35) !default;
$event-bar-selected-hover-color             : $event-bar-selected-color !default;

$event-bar-content-left-margin              : 0.75em !default;
$event-bar-content-margin                   : 0 0.5em 0 $event-bar-content-left-margin !default;

$event-hollow-l-factor                      : 2 !default;

$event-tooltip-title-weight                 : 600 !default;

$eventedit-label-width                      : 5em !default;
$eventedit-label-filler-width               : null !default;
// Min size of events/tasks
$entity-min-size                            : 6px !default;

$dependencyedit-label-width                 : 4em !default;
$dependencyedit-label-filler-width          : null !default;

$recurrenceeditor-label-width               : 10em !default;
$recurrenceeditor-label-filler-width        : null !default;

$columnlines-tick-color                     : scale_color($grid-cell-border-color, $lightness : 50%) !default;
$columnlines-major-color                    : $grid-cell-border-color !default;

$event-labels-color                         : #888 !default;
$selected-event-labels-color                : null !default;

$event-label-spacing                        : 1.5em !default;
$milestone-label-spacing                    : 3em !default;

$resourceinfo-role-color                    : $gray !default;
$avatar-initials-background                 : $event-color-blue !default;

$range-background-color                     : transparentize(#f0f0f0, .5) !default;
$range-color                                : #aaa !default;
$range-header-color                         : #fff !default;
$range-header-background-color              : scale_color($event-color-blue, $lightness : 50%) !default;

$time-selection-header-background-color     : $event-color-blue !default;
$time-selection-background-color            : scale_color($time-selection-header-background-color, $lightness : 50%, $alpha : -80%) !default;

$resourcetimerange-default-background-color : $event-color-yellow !default;
$resourcetimerange-opacity                  : .2 !default;
$resourcetimerange-label-horizontal-align   : center !default;
$resourcetimerange-label-vertical-align     : flex-end !default;
$resourcetimerange-font-size                : 12px !default;
$resourcetimerange-label-lightness          : -25% !default;
$resourcetimerange-label-saturation         : 5% !default;
$resourcetimerange-padding                  : 3px !default;

$line-color                                 : #fab005 !default;
$current-time-color                         : #fa5252 !default;
$line-start-background-color                : #fff !default;
$line-style                                 : solid !default;
$line-width                                 : 2px !default;

// z-indexes for the various stacking contexts
$timeaxis-grid-row-zindex                   : 1 !default;
$timeaxis-ticklines-zindex                  : 2 !default;
$nonworkingtime-zindex                      : 3 !default;
$resourcenonworkingtime-zindex              : 4 !default; /* Only in Pro */
$resourcetimerange-zindex                   : 5 !default;
$range-zindex                               : 6 !default;
$timeaxis-group-row-zindex                  : 7 !default;
$dependency-zindex                          : 8 !default;
$foreground-canvas-zindex                   : 9 !default;
$line-zindex                                : 10 !default;

// Inside foreground canvas
$resourcetimerange-zindex                   : 2 !default;
$scrollbuttons-zindex                       : 4 !default;
$milestone-zindex                           : 8 !default; // make sure the label of the milestone is below regular events
$event-zindex                               : 9 !default;
$selected-event-zindex                      : 10 !default;
$interacting-event-zindex                   : 11 !default;

$weekend-background-color                   : transparentize(#f0d5d5, .7) !default;
$event-default-transition                   : background-color .2s, color .2s, opacity .2s, font-weight .2s, border .2s !default;
$timeaxis-cell-header-padding               : 0.5em !default;
$timeaxis-cell-footer-border-color          : $grid-footer-border-color !default;

$arrow-size                                 : 7px !default;
$arrow-half                                 : 4px !default;
$dependency-color                           : #bbb !default;
$dependency-hover-color                     : #888 !default;

// Must use even size because border-radius is 50% to create circle.
// Odd size causes 1px to be cropped from top and left.
$terminal-size                              : 12px !default;

$percent-bar-z-index                        : 0 !default;

// In front of percentBar
$event-resize-handle-z-index                : $percent-bar-z-index + 1 !default;

// In front of resize handle
$dependency-terminal-z-index                : $event-resize-handle-z-index + 1 !default;

$dependency-valid-color                     : $green !default;
$dependency-invalid-color                   : $red !default;
$dependency-terminal-color                  : #fff !default;
$dependency-terminal-hover-color            : darken($dependency-terminal-color, 10) !default;
$dependency-terminal-valid-color            : lighten($dependency-valid-color, 30) !default;
$dependency-terminal-invalid-color          : lighten($dependency-invalid-color, 30) !default;
$dependency-tooltip-terminal-color          : $dependency-terminal-color !default;

$list-dependency-color                      : $dependency-color !default;
$list-dependency-terminal-color             : $dependency-terminal-color !default;
$list-dependency-background-color           : $green !default;

$clock-background-color                     : #fff !default;

$eventdragselect-border                     : 1px dashed $dark-gray !default;

$eventdragcreate-invalid-color              : $red !default;

// DatePicker.events
$datepicker-with-events-cell-size           : 2.8em !default;
$datepicker-with-events-cell-margin         : 1px 1px !default;
$datepicker-cell-badge-font-size            : 80% !default;
$datepicker-cell-badge-background-color     : $widget-badge-background-color !default;
$datepicker-cell-badge-color                : $widget-badge-color !default;
$datepicker-cell-badge-size                 : 1.3em !default;
$datepicker-cell-badge-transform            : null !default;

$datepicker-cell-bullet-position            : null !default;
$datepicker-cell-bullet-size                : 50% !default;
$datepicker-cell-bullet-color               : $blue !default;
$datepicker-selected-cell-bullet-color      : $datepicker-selected-cell-color !default;
$datepicker-cell-bullet-margin              : 1px !default;
$datepicker-cell-bullet-transform           : null !default;

$datepicker-with-events-cell-gap            : null !default;
$datepicker-with-events-cell-justify        : flex-start !default;
$datepicker-with-events-cell-padding-top    : 4px !default;


//region Scroll buttons
$scrollbutton-background-color              : $grid-background-color !default;
$scrollbutton-color                         : darken($grid-cell-border-color, 10%) !default;
$scrollbutton-hover-color                   : darken($grid-cell-border-color, 30%) !default;
//endregion
