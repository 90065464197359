@import '../../variables';


$separator-start-color : null !default;
$separator-end-color   : null !default;

@if lightness($panel-toolbar-background-color) > lightness($panel-toolbar-color) {
    // Light background theme
    $separator-start-color : darken($panel-toolbar-background-color, 10%);
    $separator-end-color   : darken($panel-toolbar-background-color, 5%)
} @else {
    // dark background theme
    $separator-start-color : lighten($panel-toolbar-background-color, 20%);
    $separator-end-color   : lighten($panel-toolbar-background-color, 10%);
}

.b-toolbar {
    --toolbar-min-size        : #{$panel-toolbar-content-min-size};
    --toolbar-content-padding : #{$panel-toolbar-padding};

    background-color          : $panel-toolbar-background-color;
    color                     : $panel-toolbar-color;

    &.b-dock-header, &.b-dock-pre-header {
        background-color : $panel-toolbar-dock-header-background-color;

        .b-toolbar-content {
            padding : $panel-toolbar-dock-header-padding;
        }

        // Buttons added as tools (like the overflow menu button)
        > .b-button {
            margin : $panel-toolbar-dock-header-padding;
        }

        .b-button {
            min-height : auto;
        }
    }

    &.b-dock-header {
        margin-inline-start : $panel-toolbar-dock-header-margin;
    }

    &.b-dock-pre-header {
        margin-inline-end : $panel-toolbar-dock-header-margin;
    }

    // Must not shrink when Panel loses height
    flex-shrink               : 0;

    // Scroll tools at start and end
    > .b-tool {
        align-self       : center;
        background-color : transparent;
        //background-color : rgba(100, 100, 100, 0.1);

        &.b-pressed {
            background-color : rgba(50, 50, 50, 0.2);
        }
    }

    // Icon-only buttons must not yield width
    .b-button:not(.b-text) {
        flex-shrink : 0;
    }

    .b-row-start-scroller {
        margin            : 0;
        margin-inline-end : left($panel-toolbar-padding)
    }

    &.b-dock-left {
        .b-row-start-scroller {
            margin              : 0;
            margin-inline-start : left($panel-toolbar-padding);
        }
    }

    &.b-dock-right {
        .b-row-start-scroller {
            margin              : 0;
            margin-inline-start : left($panel-toolbar-padding);
        }
    }

    .b-row-end-scroller, .b-row-menu {
        margin              : 0;
        margin-inline-start : right($panel-toolbar-padding);
    }

    .b-column-start-scroller {
        margin : 0 0 top($panel-toolbar-padding) 0;
    }

    .b-column-end-scroller, .b-column-menu {
        margin : bottom($panel-toolbar-padding) 0 0 0;
    }

    // Buttons added as tools (like the overflow menu button)
    > .b-overflow-button {
        font-size : $button-toolbar-font-size;
        margin    : $panel-toolbar-padding;
    }

    &.b-rtl > .b-overflow-button::before {
        // Flip icon for RTL
        transform : scaleX(-1);
    }

    &.b-dock-bottom {
        --toolbar-min-size        : #{$panel-bbar-content-min-size };
        --toolbar-content-padding : #{$panel-bbar-padding};
    }

    .b-toolbar-content {
        overflow : hidden;
        padding  : var(--toolbar-content-padding);
        gap      : $panel-toolbar-gap;

        .b-field {
            margin-bottom : 0;
        }

        // A filler element to push elements along.
        > .b-toolbar-fill {
            flex : 1 1 0%;
        }

        // text display elements such as titles/descriptions should be centered.
        .b-html {
            align-items : center;
        }

        &.b-overflow {
            // Fields and buttons must not flex down into obscurity when there's an overflow handler
            .b-field, .b-button {
                flex-shrink : 0;
            }
        }
    }

    &.b-dock-top,
    &.b-dock-bottom {
        .b-toolbar-content {
            min-height : var(--toolbar-min-size);

            > .b-toolbar-separator {
                margin-block              : 0.2em;
                border-style              : solid;
                border-width              : 0 1px 0 1px;
                border-inline-start-color : $separator-start-color;
                border-inline-end-color   : $separator-end-color;
            }

            > .b-widget {
                // Toolbar dictates height (for the most part, high widgets can stretch it)
                &:not(.b-button) {
                    min-height : 0;
                }
            }
        }
    }

    &.b-dock-top {
        border-bottom : $panel-toolbar-top-border-bottom;
    }

    &.b-dock-left,
    &.b-dock-right {
        .b-toolbar-content {
            min-width : var(--toolbar-min-size);

            > .b-toolbar-separator {
                margin              : 0 0.5em 0.2em 0.2em;
                border-style        : solid;
                border-width        : 1px 0 1px 0;
                border-top-color    : $separator-start-color;
                border-bottom-color : $separator-end-color;
            }

            > .b-widget {
                // Toolbar dictates width (for the most part, wide widgets can stretch it)
                &:not(.b-button) {
                    min-width : 0;
                }
            }
        }
    }
}

// When displayed in a tabpanel, match its color
.b-tabpanel .b-panel .b-toolbar {
    background-color : $tabpanel-background-color;
}

// When used standalone, use panel background color
.b-toolbar.b-outer {
    background-color : $popup-background-color;
}

.b-toolbar-overflow-menu > .b-panel-body-wrap > .b-menu-content {
    .b-widget {
        // Don't allow horizontal flex styles to apply in the vertical layout of a Menu
        flex  : 0 0 auto !important;

        // Width must obey the Menu's align-items:stretch
        width : auto;
    }
}
