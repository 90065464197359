@import '../variables';

.b-sch-summarybar {
    padding         : 0;
    flex-direction  : row;
    // Grid uses a border on summaries since cells by default have a border,
    // but Scheduler does not use a border for timeaxis cells
    border-inline-end : none;

    // Override .b-grid-footer's centering
    justify-content : flex-start;

    // Override the b-fill-last-column stretching
    flex-grow       : unset !important;

    .b-timeaxis-tick {
        display         : flex;
        flex-direction  : column;
        text-align      : center;
        overflow        : hidden;
        justify-content : center;
        align-items     : stretch;
    }
}

.b-sch-summarybar:not(.b-sch-vertical) {
    .b-timeaxis-tick {
        padding : .5em 0;

        &:not(:last-child) {
            border-inline-end : 1px solid $timeaxis-cell-footer-border-color;
        }
    }
}

.b-sch-vertical .b-sch-summarybar {
    flex-direction : column;
    align-items    : stretch;
    background     : $grid-footer-background-color !important;
    color          : $grid-footer-text-color;

    .b-timeaxis-tick {
        padding : 0 0.5em;

        &:not(:last-child) {
            border-bottom : 1px solid $timeaxis-cell-footer-border-color;
        }
    }
}

.b-timeaxis-summary-tip {
    display        : flex;
    flex-direction : row;
    flex-wrap      : wrap;

    header {
        width         : 100%;
        text-align    : center;
        font-weight   : 700;
        margin-bottom : 1em;
    }

    label {
        flex : 1 1 50%;
    }

    .b-timeaxis-summary-value {
        flex : 0 0 auto;
    }

}
