@import '../variables';

.b-dependencyeditor.b-popup {
    width : 29em;

    .b-popup-content {
        padding : 1em 1em 0 1em;

        .b-textfield,
        .b-checkbox {
            label {
                flex : 1 0 $dependencyedit-label-width;
            }

            .b-checkbox-label {
                text-align : start;
            }
        }
    }

    .b-bottom-toolbar {
        padding : 1em 1em 0.4em 1em;

        .b-label-filler {
            @if $dependencyedit-label-filler-width == null {
                display : none;
            }
            width : $dependencyedit-label-filler-width;
        }

        > button {
            flex              : 1;
            margin-inline-end : .5em;

            &:last-child {
                margin-inline-end : 0;
            }
        }
    }
}
