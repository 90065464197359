@import '../variables';

.b-histogram-bar-tooltip {
    span.b-underallocated {
        color : $resourcehistogram-underallocated-bar-color;
    }
    span.b-overallocated {
        color : $resourcehistogram-overallocated-bar-color;
    }
}

.b-resourcehistogram-histogram {
    padding          : 0;
    background-color : transparent;

    .b-bar-legend {
        font-size      : $resourcehistogram-bar-legend-font-size;

        // So as not to interfere with mouseover of the bars
        pointer-events : none;
    }

    rect,
    rect.b-series-index-0 {
        fill : $resourcehistogram-bar-color;

        &.b-underallocated {
            fill : $resourcehistogram-underallocated-bar-color;

            &:hover {
                fill : $resourcehistogram-underallocated-bar-hover-color;
            }
        }

        &.b-overallocated {
            fill : $resourcehistogram-overallocated-bar-color;

            &:hover {
                fill : $resourcehistogram-overallocated-bar-hover-color;
            }
        }

        &:hover {
            fill : $resourcehistogram-bar-hover-color;
        }
    }
}
