.b-task-rollup-wrap {
    grid-area  : rollup;
    display    : block;
    align-self : stretch;
    position   : relative;
    min-height : 3px;
    height     : 0.2em;
    margin-top : .15em;
    grid-area  : rollup;
}

.b-task-rollup {
    background-color : var(--event-background-color);
    position         : absolute;
    height           : 100%;
    opacity          : .8;
}

.b-task-rollup.b-inactive {
    filter  : $gantt-inactive-task-filter;
    opacity : $gantt-inactive-task-opacity;
}

.b-rollups {
    .b-baseline-wrap {
        top : 0.6em;
    }

    .b-gantt-task-parent:not(.b-milestone-wrap) .b-gantt-task {
        min-height : 1em;
    }
}


.b-task-rollup.b-milestone {
    top              : 50%;
    width            : $gantt-rollup-milestone-size;
    height           : $gantt-rollup-milestone-size;
    transform        : translateY(-50%) translateX(-50%) rotate(45deg);
    transform-origin : 50% 50%;
    z-index          : 2;
    background-color : $gantt-rollup-milestone-background-color;
    opacity          : .9;
}

.b-gantt-rollups-tooltip {
    .b-gantt-task-title:not(:first-child) {
        margin-top : 1em;
    }

    td:last-child {
        padding-inline-start : 1em;
    }
}
