@import '../variables';

.b-resource-time-range-canvas {
    z-index : $resourcetimerange-zindex;
}

/* Affects both ResourceTimeRanges & ResourceNonWorkingTime */
.b-sch-canvas.b-interactive .b-sch-resourcetimerange {
    pointer-events : auto;
}

.b-sch-resourcetimerange {
    display          : flex;
    position         : absolute;
    left             : 0;
    background-color : transparentize($resourcetimerange-default-background-color, 1 - $resourcetimerange-opacity);
    color            : scale_color($resourcetimerange-default-background-color, $lightness : $resourcetimerange-label-lightness, $saturation : $resourcetimerange-label-saturation);
    justify-content  : $resourcetimerange-label-horizontal-align;
    align-items      : $resourcetimerange-label-vertical-align;

    [data-task-feature] {
        display     : flex;
        height      : 100%;
        align-items : center;
        font-size   : $resourcetimerange-font-size; // em would be fraction of rowHeight, not usable here
    }

    .b-animating & {
        transition : $event-default-transition, transform .2s, left .2s, right .2s, width .2s, height .2s, font-size .2s;
    }

    .b-sch-event-withicon {
        .b-fa {
            margin-inline-end : .4em;
            line-height       : inherit;
        }
    }

    .b-sch-event-content {
        margin : $resourcetimerange-padding;
    }
}

.b-sch-vertical .b-sch-resourcetimerange {
    justify-content : center;
    align-items     : flex-start;
}

@mixin resourcetimerange-plain($cls, $color) {
    .b-sch-color-#{$cls}.b-sch-resourcetimerange {
        background-color : transparentize($color, 1 - $resourcetimerange-opacity);
        color            : scale_color($color, $lightness : $resourcetimerange-label-lightness, $saturation : $resourcetimerange-label-saturation);
    }
}

@include resourcetimerange-plain("red", $event-color-red);
@include resourcetimerange-plain("pink", $event-color-pink);
@include resourcetimerange-plain("purple", $event-color-purple);
@include resourcetimerange-plain("violet", $event-color-violet);
@include resourcetimerange-plain("indigo", $event-color-indigo);
@include resourcetimerange-plain("blue", $event-color-blue);
@include resourcetimerange-plain("cyan", $event-color-cyan);
@include resourcetimerange-plain("teal", $event-color-teal);
@include resourcetimerange-plain("green", $event-color-green);
@include resourcetimerange-plain("lime", $event-color-lime);
@include resourcetimerange-plain("yellow", $event-color-yellow);
@include resourcetimerange-plain("orange", $event-color-orange);
@include resourcetimerange-plain("deep-orange", $event-color-deep-orange);
@include resourcetimerange-plain("gray", $event-color-gray);
@include resourcetimerange-plain("gantt-green", $event-color-gantt-green);
