.b-pickerfield {
    &.b-open {
        .b-field-inner {
            border-color : $main-color;
        }
    }

    &:not(.b-readonly):not(.b-disabled) {
        input[readonly] {
            cursor : pointer;
        }
    }
}
