@import '../variables';

.b-calendareditor {

    .b-tabpanel {
        height     : 100%;
        background : transparent;

        .b-tabpanel-item:first-child {
            overflow-y : auto;
        }

        .b-tabpanel-body {
            padding-right  : 0;
            padding-bottom : 0;
            background     : transparent;
        }

        .b-panel,
        .b-calendarpanel .b-panel-body-wrap,
        .b-panel-content,
        .b-toolbar.b-tabbar,
        .b-tabpanel-body-wrap {
            background : transparent;
        }
    }

    [data-ref="generalTab"] {
        [data-ref="nameField"] {
            margin-inline-end : 0.5em;
        }

        [data-ref="parentField"] {
            margin-inline-end : 0.5em;
        }

        [data-ref="unspecifiedTimeIsWorkingField"] {
            margin-inline-start : 4.7em;
        }

        hr {
            border     : none;
            border-top : 1px solid $field-border-color;
        }
    }

    .b-widget.b-html {
        user-select : none;
    }

    .b-calendareditor-content {
        padding-bottom : 0;
    }

    .b-checkbox .b-field-inner .b-checkbox-label {
        text-transform : none;
    }
}
