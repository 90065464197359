.b-eventbuffer {
    .b-sch-event {
        width : 100%;
    }

    &.b-sch-vertical .b-sch-event-wrap {
        contain : size layout;
    }

    .b-sch-event-buffer-before {
        grid-area : start;
    }

    .b-sch-event-buffer-after {
        grid-area : end;
    }

    &.b-sch-horizontal .b-sch-event-wrap {
        .b-sch-event-buffer {
            height     : 5px;
            background : repeating-linear-gradient(180deg, transparent, transparent 30%, #ccc 30%, #ccc 70%, transparent 70%, transparent 100%);
        }

        .b-sch-event-buffer-before {
            flex-direction  : row;
            justify-content : flex-end;

            .b-buffer-label {
                margin-inline-end  : auto;
                padding-inline-end : 0.5em;
            }

            .b-buffer-label,
            i {
                transform : translate(.1em, -1.25em);
            }

            i {
                margin-inline-end : .5em;
            }
        }

        .b-sch-event-buffer-after {
            flex-direction  : row-reverse;
            justify-content : flex-end;

            .b-buffer-label,
            i {
                transform : translate(-.1em, -1.25em);
            }

            .b-buffer-label {
                text-align          : right;
                margin-inline-start : .5em;
            }

            i {
                margin-inline-start : auto;
                padding-inline-start : 0.5em;
            }
        }
    }

    &.b-sch-vertical .b-sch-event-wrap {
        .b-sch-event-buffer {
            width        : 5px;
            justify-self : center;
            background   : repeating-linear-gradient(90deg, transparent, transparent 30%, #ccc 30%, #ccc 70%, transparent 70%, transparent 100%);
        }

        .b-sch-event-buffer-before {
            flex-direction  : column;
            justify-content : flex-end;
            .b-buffer-label {
                margin-inline-end : .5em;
            }
        }

        .b-buffer-label,
        i {
            margin-inline-end : -1.3em;
            writing-mode : vertical-rl;
        }

        .b-sch-event-buffer-after {
            flex-direction  : column-reverse;
            justify-content : flex-end;

            .b-buffer-label {
                margin-inline-start : .5em;
            }
        }
    }

    .b-sch-event-buffer {
        display     : flex;
        color       : #aaa;
        white-space : nowrap;
        font-size   : 10px;
    }
}

.b-animating {
    .b-sch-event-buffer {
        transition : width .2s;
    }
}
